import { SimpleForm,ReferenceInput,SelectInput,TextField,TextInput} from "react-admin";

export const ProjectCreateInputs = (props: any) => (
  <SimpleForm sanitizeEmptyValues={true} onSubmit={props.projectSave}>
    <TextInput source="name" isRequired />
    <ReferenceInput source="customer" reference="Customer">
      <SelectInput optionText="name" isRequired />
    </ReferenceInput>
  </SimpleForm>
);

export const ProjectEditInputs = (props: any) => (
  <SimpleForm sanitizeEmptyValues={true} onSubmit={props.ProjectSave}>
    <ReferenceInput source="name" reference="Project">
      <SelectInput optionText="name" isRequired />
    </ReferenceInput>
    <ReferenceInput source="customer" reference="Customer">
      <SelectInput optionText="name" isRequired />
    </ReferenceInput>
    <TextField source="customerName" />
    <TextField source="code" />
  </SimpleForm>
);
