import { ReportsShowFields, ReportsListFields } from '../fields';
import { Show,ListBase,useTranslate  } from 'react-admin';
import { ReportsFilters } from '../filters';
import  ListToolbar  from '../Toolbar';
import { Box, Tab,Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import * as icon from '../icons';
import { styles } from '../style';
import { TabPanelProps } from '../types';
import { IndividualReport,GlobalReport } from '../components';

export const ReportsShow = () => (
    <Show>
        <ReportsShowFields />
    </Show>
);

export function a11yProps(index: number) {
    return {
      id: `stats-tab-${index}`,
      'aria-controls': `stats-tabpanel-${index}`,
    };
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`stats-tabpanel-${index}`}
        aria-labelledby={`stats-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
}

export const ReportsList = () => {
    const t = useTranslate();
    const [value, setValue] = useState(0);
    const handleChange = (event:any,newValue:number) => {setValue(newValue)};

    return(
        <ListBase filter={{type: 'GLOBAL'}} filterDefaultValues={{type:'GLOBAL'}}>
            <Tabs value={value} onChange={handleChange}>
                <Tab label={<Box sx={[styles.around,styles.alignCenter]}><icon.InvoicesIcon/>{t('ra.categories.reports')}</Box>} {...a11yProps(0)}  />
                <Tab label={<Box sx={[styles.around,styles.alignCenter]}><icon.ReportsIcon/>{t('ra.categories.globalReports')}</Box>} {...a11yProps(1)}  />
                <Tab label={<Box sx={[styles.around,styles.alignCenter]}><icon.GroupIcon/>{t('ra.categories.individualReports')}</Box>} {...a11yProps(2)}  />
            </Tabs>
            <TabPanel value={value} index={0}>
                <ListToolbar filters={ReportsFilters} />
                <ReportsListFields />
            </TabPanel>
            <TabPanel sx={{textAlign:'center', display:'flex',justifyContent:'center',width:'100%'}} value={value} index={1}>
                <GlobalReport />
            </TabPanel>
            <TabPanel sx={{textAlign:'center', display:'flex',justifyContent:'center',width:'100%'}} value={value} index={2}>
                <IndividualReport />
            </TabPanel>
        </ListBase>
    
)};

