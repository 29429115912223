import { SimpleShowLayout,Datagrid, TextField,DateField,FunctionField } from 'react-admin';
import { rowStyle } from '../style';
import { JsonObjectField } from './CustomFields';
//TODO: custom function field that map the json object and return fields based on it

export const ReportsShowFields = () => (
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" />
      <DateField source="dateStart" />
      <DateField source="dateEnd" />
      <TextField source="type"/>
      <TextField source="periodicity"/>
      <FunctionField label="Total Compte de réserve" render={(record:any) => record.result[0] !== '{' ? 'pas de données' : `${JSON.parse(`{${record.result}}`).employeesTotalBaseAccountAmount / 100}€`} />
      <FunctionField label="Total Frais de gestion" render={(record:any) => record.result[0] !== '{' ? 'pas de données' : `${JSON.parse(`{${record.result}}`).employeesTotalGwFeesAmount / 100}€`} />
      <FunctionField label="Nombre de salariés" render={(record:any) => record.result[0] !== '{' ? 'pas de données' : `${JSON.parse(`{${record.result}}`).employees}`} />
    </SimpleShowLayout>
);

export const ReportsListFields = () => {  

    return(
        <Datagrid rowStyle={rowStyle} rowClick="show">
            <DateField source="createdAt" />
            <DateField source="dateStart" />
            <DateField source="dateEnd" />
            <TextField source="type"/>
            <TextField source="periodicity"/>
            <FunctionField label="Total Compte de réserve" render={(record:any) => record.result[0] !== '{' ? 'pas de données' : `${JSON.parse(`{${record.result}}`).employeesTotalBaseAccountAmount / 100}€`} />
            <FunctionField label="Total Frais de gestion" render={(record:any) => record.result[0] !== '{' ? 'pas de données' : `${JSON.parse(`{${record.result}}`).employeesTotalGwFeesAmount / 100}€`} />
            <FunctionField label="Nombre de salariés" render={(record:any) => record.result[0] !== '{' ? 'pas de données' : `${JSON.parse(`{${record.result}}`).employees}`} />
            <JsonObjectField label="Résultats" />
        </Datagrid>    
    )
};