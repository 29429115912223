import { SimpleShowLayout,TextField,BooleanField,DateField,Datagrid,ShowButton,SelectField } from 'react-admin';
import {CreateAmendmentButton,ToCompletedButton} from '../components';
import { LockTwoTone,LockOpenTwoTone } from '@mui/icons-material';
import { CentsField, CustomerField, EmployeeField, StatusField } from './CustomFields';
import { rowStyle } from '../style';

export const ContractShowFields = () => (
    <SimpleShowLayout>
        <CustomerField label='Client' />
        <DateField source="dateStart" />
        <DateField source="dateEnd" />
        <CentsField source="rate" />
        <EmployeeField label='Employé' />
        <TextField source="project" />
        <SelectField source='billingMode' fullWidth choices={[{id:"rate",name:"TJM"},{id:"invoice",name:"Factures/Devis"}]} />
        <TextField source="externalId" />
        <BooleanField source="locked" />
        <TextField source="status" />
        <BooleanField source="is_amendment" />
    </SimpleShowLayout>
);

export const ContractListFields = () => (
    <Datagrid rowStyle={rowStyle} size='small' optimized>
        <CustomerField label='Client' />
        <DateField source="dateStart" />
        <DateField source="dateEnd" />
        <CentsField source="rate" />
        <EmployeeField label='Employé' />
        <StatusField source="status" />
        <TextField source='periodsRemaining' />
        <BooleanField color={"secondary"} source="locked" TrueIcon={LockTwoTone} FalseIcon={LockOpenTwoTone} />
        <BooleanField source="is_amendment" />
        <ShowButton label="Détails" />
        <CreateAmendmentButton />
        <ToCompletedButton />
    </Datagrid>
);