import { Box, Card, Chip, CircularProgress, Typography } from "@mui/material";
import { PeriodIcon,BusinessDayIcon,ContractIcon,StatsIcon } from "../icons";
import { styles } from "../style";
export const DashboardHeaderCards = ({data}:any) => {

    const alliases = [{value:"stats",alias:"Statistiques",icon:<StatsIcon/>,color:'primary'},
        {value:"contractsNearEnd",alias:"Fin des contrats",icon:<ContractIcon/>,color:'error'},
        {value:"periodsToComplete",alias:"Périodes à compléter",icon:<PeriodIcon/>,color:"warning"},
        {value:"businessDaysToValidate",alias: "Congés à valider",icon: <BusinessDayIcon/>,color:'primary'}
    ]

    let cards:any[] = [];

    Object?.entries(data)?.forEach((kv:any,i:number) => {
        delete data['id']
        delete data['contractsNearEndIds']
        delete data['periodsToCompleteIds']
        delete data['businessDaysToValidateIds']
        //@ts-ignore
        cards.push({ name: <Chip color={alliases[i]?.color || 'primary'} icon={alliases[i]?.icon} label={alliases[i]?.alias} />, values: kv[1] })
    })

    return (
        <Card sx={[styles.row,styles.around,{padding:2}]}>
        { cards?.length === 0 ? <CircularProgress /> : cards?.map((card:any,index:number) =>
            <Card key={card.name+index} sx={[styles.card,styles.pt10,{margin:2}]}>
                <Typography sx={styles.mb2} key={card.name}>{card.name}</Typography>
                {alliases[index]?.value !== 'stats'
                ? <Box>
                    <Chip label={card?.values?.length + ' en attente'} />
                  </Box>
                :''}
                {card?.values?.map((v:any,i:any) => typeof v === 'string' ?  <Typography key={i}>{<Chip sx={styles.mb1} label={v} />}</Typography> : '' )}
            </Card>
        )}
    </Card>
    )
}

