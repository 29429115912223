import { Box, Tabs,Tab,Chip,Card } from "@mui/material";
import { useState } from "react";
import { Loading,useTranslate,useGetList } from "react-admin";
import { styles } from "../style";
import { TabPanel,a11yProps } from "../screens";
import * as icon from '../icons'
import { StatsTable } from "@g8wdev/react-ui/lib/StatsTable";

const actualYear = new Date().getFullYear();
const pastYears = [actualYear,actualYear - 1,actualYear - 2,actualYear - 3,actualYear - 4,actualYear - 5];
const type = "INDIVIDUAL";

export const IndividualReport = () => {
    
    const employees = useGetList("Employee");
    const {data} = useGetList('Report',{filter:{type:'INDIVIDUAL'}});
    const t = useTranslate();
    const [value,setValue] = useState(0);
    const handleChange = (event:any,newValue:number) => {setValue(newValue)};

    if (!data || !employees.data) return <Loading />
    if (data.length === 0 || employees.data.length === 0) return <Box>{t('ra.messages.noReports')}</Box>
    
    return(
            <Box sx={styles.tabs}>
                <Tabs value={value} onChange={handleChange}>
                    {pastYears.map((year:number,index:number) =>
                        <Tab key={index} label={<Box sx={[styles.around, styles.alignCenter]}><Box sx={{marginRight:1}}><icon.GroupIcon /></Box><Box>{year}</Box></Box>} {...a11yProps(index)} />
                    )}
                </Tabs>
                {pastYears.map((year:number,index:number) => 
                    <TabPanel key={index} value={value} index={index}>
                        {employees?.data?.map((employee:any,i:number) =>
                            <Card key={i} sx={{marginBottom: 5,padding:2,borderRadius:5}}>
                                <Chip color={'primary'} sx={{marginBottom:2}} label={employee.firstName + ' ' + employee.lastName} />
                                <StatsTable data={data.filter((report:any) => report.year === year && report.employee === employee.id)} type={type} year={year} />
                            </Card>
                        )}
                    </TabPanel>
                )}
            </Box>
    );
};
