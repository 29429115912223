import FileUploader from  '@genstackio/react-admin-ui/lib/molecules/FileUploader';
import buildUploader from '@genstackio/react-admin-core/lib/utils/buildUploader';
import { useInput } from "react-admin";

export const MyFileInput = ({source,grabUrl}:any) => {
    const userContext = { getTokens: () => JSON.parse(localStorage.getItem('userInfos') || '')};
    const uploader = buildUploader(process.env.REACT_APP_UPLOADS_ENDPOINT);
    let url: any;
    
    const handleUpload = async () => {
        const response = await uploader("mainFile.url",userContext);
        url = response.meta.fileUrl;
        await grabUrl(url);
        return response;
    } 
        const {field} = useInput({source});
        return ( <FileUploader url={url} getUploadParams={() => handleUpload()}  {...field} /> )
}


export const MyImageInput = ({source,grabUrl}:any) => {
    const userContext = { getTokens: () => JSON.parse(localStorage.getItem('userInfos') || '')};
    const uploader = buildUploader(process.env.REACT_APP_UPLOADS_ENDPOINT);
    let url: any;
    
    const handleUpload = async () => {
        const response = await uploader("mainImage.url",userContext);
        url = response.meta.fileUrl;
        await grabUrl(url);
        return response;
    } 
        const {field} = useInput({source});
        return ( <FileUploader url={url} getUploadParams={() => handleUpload()}  {...field} /> )
}