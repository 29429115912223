import { SimpleShowLayout,DateField,Datagrid,TextField,NumberField,FunctionField } from "react-admin";
import  {CentsField,ContractField,EmployeeField,PercentField}  from './CustomFields';
import { Chip, Typography } from "@mui/material";
import { periodsRowStyle } from '../style';

export const PeriodShowFields = () => {

return(
    <SimpleShowLayout>
        <EmployeeField label='Employé' />
        <DateField source="dateStart" />
        <DateField source="dateEnd" />
        <TextField source="contractName" />
        <TextField source="customerName" />
        <NumberField source="workedDay" options={{ style: 'unit', unit:'day' }} />
        <PercentField source="periodPercent" />
        <CentsField source="periodTurnover" />
        <CentsField source="internalFees"   />
        <CentsField source="periodExpense"   />
        <TextField source="externalId" />
        <FunctionField label="Dépenses" render={(record:any) => record?.expenses?.length === 0 ? 'pas de données' : record?.expenses?.map((expense:any) => expense.name !== undefined ? <Chip sx={{marginRight:1}} color='error' label={<Typography>{expense.name} - { expense?.cost || expense?.amount / 100 }€</Typography>} /> : '')} />
        <FunctionField label="Autres gains" render={(record:any) => record?.earnings?.length === 0 ? 'pas de données' : record?.earnings?.map((earning:any) => earning?.label !== null && earning?.amount !== null ? <Chip sx={{marginRight:1}} color='info' label={<Typography>{earning?.label} - { earning?.amount / 100 }€</Typography>} /> : '')} />
        <FunctionField label="Factures" render={(record:any) => record?.bills?.length === 0 ? 'pas de données' : record?.bills?.map((bill:any) => bill?.clientName !== null && bill?.price !== null ? <Chip sx={{marginRight:1}} color='info' label={<Typography>{bill?.clientName} - { bill?.price / 100 }€</Typography>} /> : '')} />
    </SimpleShowLayout>
);
}

export const PeriodListFields = () => (
    <Datagrid rowStyle={periodsRowStyle} rowClick="show">
        <EmployeeField label='Employé' />
        <DateField source="dateStart" />
        <DateField source="dateEnd" />
        <ContractField label='Contrat' />
        <TextField source="customerName" />
        <NumberField source="workedDay" options={{ style: 'unit', unit:'day' }} />
        <PercentField source="periodPercent" />
        <CentsField label="TJM" source="contractRate" />
        <CentsField source="periodTurnover" />
        <CentsField source="internalFees"   />
        <CentsField source="periodExpense"   />
</Datagrid>
);