import { SimpleForm,ReferenceInput,AutocompleteInput,BooleanInput,DateInput } from 'react-admin';
import { grabPayslipUrl } from '../screens';
import { MyFileInput } from './CustomInputs';

export const PayslipCreateInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.payslipSave}>
            <ReferenceInput source="employee" reference="Employee">
                    <AutocompleteInput optionText="lastName" fullWidth isRequired/>
            </ReferenceInput>
            <ReferenceInput source="employee" reference="Employee">
                    <AutocompleteInput optionText="firstName" fullWidth isRequired disabled  />
            </ReferenceInput>
            <ReferenceInput source="externalId" reference="Payslip">
                <AutocompleteInput optionText="externalId" fullWidth />
            </ReferenceInput>
            <DateInput parse={v => Date.parse(v)} source="date" required />
            <BooleanInput source="notify" />
            <MyFileInput {...props} source="mainFile.url" grabUrl={grabPayslipUrl}  />
    </SimpleForm>
);

export const PayslipEditInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.payslipSave}>
                <ReferenceInput source="employee" reference="Employee">
                    <AutocompleteInput optionText="lastName" fullWidth isRequired/>
                </ReferenceInput>
                <ReferenceInput source="employee" reference="Employee">
                    <AutocompleteInput optionText="firstName" fullWidth isRequired disabled  />
                </ReferenceInput>
                <ReferenceInput source="externalId" reference="Payslip">
                    <AutocompleteInput optionText="externalId" defaultValue={"externalId"} fullWidth />
                </ReferenceInput>
                <DateInput parse={v => Date.parse(v)} source="date" defaultValue={"date"} required />
                <BooleanInput source="notify" defaultValue={"notify"} />
                <MyFileInput {...props} source="mainFile.url" grabUrl={grabPayslipUrl}  />      
    </SimpleForm>
);