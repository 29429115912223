import { CentsField, CustomerField, EmployeeField } from './CustomFields';
import { SimpleShowLayout,TextField,Datagrid,DateField,FileField } from 'react-admin';
import { rowStyle } from '../style';

export const InvoiceShowFields =() => (
    <SimpleShowLayout>
        <EmployeeField label='Employé' />
        <CustomerField label='Client' />
        <CentsField source="price" /> 
        <TextField source="projectName" />
        <TextField source="description" />
        <DateField source="month" />
        <FileField src="mainFile.url" source="mainFile.url" title="Télécharger" download={true} />
    </SimpleShowLayout>
);

export const InvoiceListFields = () => (
    <Datagrid rowStyle={rowStyle} rowClick="show">
        <EmployeeField label='Employé' />
        <CustomerField label='Client' />
        <DateField source="month" />
        <CentsField source="price" /> 
        <TextField source="projectName" />
        <TextField source="description" />
        <FileField src="mainFile.url" source="mainFile.url" title="Télécharger" download={true} />
    </Datagrid>
)