import { SimpleShowLayout,Datagrid,DateField,TextField,EmailField,FileField } from 'react-admin';
import { rowStyle } from '../style';
import { EmployeeField } from './CustomFields';

export const PayslipShowFields = () => (
    <SimpleShowLayout>
        <DateField source="date" />
        <FileField src="mainFile.url" source="mainFile.url" title="PDF" download={true} />
        <EmployeeField label='Employé' />
        <EmailField source="employeeEmail" />
        <TextField source="employeePhone" />
    </SimpleShowLayout>
);

export const PayslipListFields = () => (
    <Datagrid rowStyle={rowStyle} rowClick="show">
        <DateField label="Date" source="date" />
        <FileField src="mainFile.url" source="mainFile.url" title="PDF" download={true} />
        <EmployeeField label='Employé' />
        <EmailField source="employeeEmail" />
        <TextField source="employeePhone" />
    </Datagrid>
);