// import { SimpleForm,TextInput,ReferenceInput, AutocompleteInput,BooleanInput, SelectInput,useRecordContext,regex } from "react-admin"
import { Box } from "@mui/system";
import { useState } from "react";
import { employeeRenderer } from "../renderers";
import { SimpleForm,TextInput,ReferenceInput, AutocompleteInput,BooleanInput, SelectInput, ArrayInput, SimpleFormIterator,useRecordContext,regex } from "react-admin"
import { CentsInput } from "./NumberInput";

export const EmployeeCreateInputs = (props:any) => {
    const [createUserAccess,setCreateUserAccess] = useState(false);

    return(
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.employeeSave}>
            <TextInput source="firstName" sx={{width: "42%"}} isRequired />
            <TextInput source="lastName" sx={{width: "42%"}} isRequired />
            <TextInput type='email' source="email" sx={{width: "42%"}} isRequired />
            <TextInput source="externalId" sx={{width: "42%"}} />
            <TextInput source="mooncardId" sx={{width: "42%"}} />
            <ReferenceInput reference="User" source="user">
                <AutocompleteInput sx={{width:'42%'}} disabled={createUserAccess} label="Utilisateur Associé" optionText="firstName" />
            </ReferenceInput>
            <ArrayInput source="autoExpenses">
                <SimpleFormIterator inline>
                    <TextInput label="Libéllé" source="name" />
                    <CentsInput label="Montant" source="amount" />
                    <BooleanInput defaultValue={true} label="Afficher" source="display" disabled />
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput onChange={() => setCreateUserAccess(!createUserAccess)} source="createUser" />
            {createUserAccess
                ? <Box sx={{display:'flex',flexDirection:'column',width:'42%'}}>
                    <TextInput source="userEmail" />
                    <TextInput source='userPassword' isRequired />
                  </Box>
                : ''
            }
    </SimpleForm>
)};

export const EmployeeEditInputs = (props:any) => {

    const record = useRecordContext();
    const [createUserAccess,setCreateUserAccess] = useState(false);
    const [updateUserAccess,setUpdateUserAccess] = useState(false);
    const [linkedUser,setLinkedUser] = useState(record.user ? true : false);

    const handleLinkedUser = (value:boolean,id:string) => {
        setLinkedUser(value)
        setUpdateUserAccess(value)
    }
        
    const validatePassword = regex(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-={[}]|:;"'<,>.?])[a-zA-Z0-9!@#$%^&*()_+\-={[}]|:;"'<,>.?]{8,}$/);

    return (
        <SimpleForm sanitizeEmptyValues onSubmit={props.employeeSave}>
                    <TextInput fullWidth source="firstName" isRequired />
                    <TextInput fullWidth source="lastName" isRequired />
                    <TextInput fullWidth type='email' source="email" isRequired />
                    <SelectInput fullWidth source='status' choices={[{id:"CREATED",name:"Crée"},{id:"LOCKED",name:"Verrouillé"}]} />
                    <TextInput fullWidth source="externalId" />
                    <TextInput fullWidth source="mooncardId" />
                    <ReferenceInput reference="User" source="user">
                        <AutocompleteInput fullWidth disabled={createUserAccess} onChange={(user:any) => user.length !== 0 ? handleLinkedUser(true,user) : handleLinkedUser(false,user)} label="Utilisateur Associé" optionText={employeeRenderer} />
                    </ReferenceInput>
                    <ArrayInput source="autoExpenses">
                        <SimpleFormIterator inline>
                            <TextInput label="Libéllé" source="name" />
                            <CentsInput label="Montant" source="amount" />
                            <BooleanInput defaultValue={true} label="Afficher" source="display" disabled />
                        </SimpleFormIterator>
                    </ArrayInput>
                    {
                    updateUserAccess
                        ? 
                            <Box>
                                <TextInput fullWidth label='Email utilisateur' source="userEmail" />
                                <TextInput fullWidth validate={validatePassword} source='userPassword' />
                            </Box>
                        :  <Box sx={{display:'flex',alignItems:'center'}}> <BooleanInput disabled={linkedUser} onChange={() => setCreateUserAccess(!createUserAccess)} source="createUser" /> </Box>
                    }
                    {
                    createUserAccess
                        ?
                            <Box>
                                <TextInput fullWidth defaultValue={record.email} source="userEmail" isRequired />
                                <TextInput fullWidth validate={validatePassword} source='userPassword' isRequired />
                            </Box>
                        :  ''
                    }
        </SimpleForm>
    )
}