import {Edit,useUpdate,Show,List,Create,useCreate,useNotify,useRedirect } from 'react-admin';
import { ProjectShowFields,ProjectListFields } from '../fields';
import { ProjectFilters } from '../filters';
import { ProjectCreateInputs, ProjectEditInputs } from '../inputs';

export const ProjectShow = () => (
    <Show>
        <ProjectShowFields />
    </Show>
);

export const ProjectList = () => (
    <List filters={ProjectFilters}>
        <ProjectListFields />
    </List>
);

export const ProjectCreate =  (props:any) => {
    const [create] = useCreate()
    const redirect = useRedirect()
    const notify = useNotify()
    const projectSave = (data:any) => {
        create('Project',{data: {data}},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Projet crée avec succès');
        redirect('list','project');
    })
    }
    return(
        <Create>
            <ProjectCreateInputs projectSave={projectSave} />
        </Create>
    )
};

export const ProjectEdit = () => {
    const [update] = useUpdate()
    const redirect = useRedirect()
    const notify = useNotify()
    const projectSave:any = (datas:any) => {const data = {}
        update('Project',{data: {data},id:datas.id},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Projet modifié avec succès');
        redirect('list','project');
    })
    }
    return(
    <Edit>
        <ProjectEditInputs projectSave={projectSave} />
    </Edit>
)};