import { Edit,useUpdate,Show,List,Create,useCreate,useNotify,useRedirect } from 'react-admin';
import { ContractFilters } from '../filters';
import { ContractShowFields,ContractListFields } from '../fields'; 
import { ContractCreateInputs,ContractEditInputs } from '../inputs/ContractInputs';

export const ContractShow = () => (
    <Show>
        <ContractShowFields />
    </Show>
);

export const ContractList = () => (
    <List filters={ContractFilters}>
        <ContractListFields />
    </List>
);

export const ContractCreate =  () => {
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const contractSave = (data:any) => {
        create('Contract',{data: {data}},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        if (data.is_amendment === true) {
            notify('Avenant crée avec succès')
        } else {
            notify('Contrat crée avec succès');
        }
        redirect('list','contract');
    })
    };
    return(
    <Create>
        <ContractCreateInputs contractSave={contractSave} />
    </Create>)
    
};

export const ContractEdit = () => {
    const [update] = useUpdate()
    const notify = useNotify();
    const redirect = useRedirect();
    const contractSave = (datas:any) => {
        const data = {
            customer: datas.customer,
            dateStart: datas.dateStart,
            dateEnd: datas.dateEnd,
            rate: datas.rate,
            employeeFirstName: datas.employeeFirstName,
            employeeLastName: datas.employeeLastName,
            employee: datas.employee,
            project: datas.project,
            status: datas.status,
            locked: datas.locked,
            is_amendment: datas.is_amendment,
        }
        update('Contract',{data: {data},id:datas.id},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Contrat modifié avec succès');
        redirect('list','contract');
    })
    };
    return(
    <Edit>
        <ContractEditInputs contractSave={contractSave} />
    </Edit>
)};