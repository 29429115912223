import { SimpleShowLayout,Datagrid, TextField,DateField,ReferenceOneField,NumberField, FileField } from 'react-admin';
import { rowStyle } from '../style';

export const EreportsShowFields = () => (
    <SimpleShowLayout>
      <DateField source='month' />
        <NumberField source='workedDay' />
        <FileField src="mainFile.url" source="mainFile.url" title="Télécharger" download={true} />
        <ReferenceOneField source="contract" target="id" reference="Contract">
            <TextField source="endCustomerName" />
        </ReferenceOneField>
    </SimpleShowLayout>
);

export const EreportsListFields = () => (
    <Datagrid rowStyle={rowStyle} rowClick="show">
        <DateField source='month' label='Date' />
        <NumberField label='Jours Travaillés' source='workedDay' />
        <FileField src="mainFile.url" source="mainFile.url" title="Télécharger" label="Compte rendu d'activité" download={true} />
        <ReferenceOneField label="Contrat" source="contract" target="id" reference="Contract">
            <TextField source="endCustomerName" />
        </ReferenceOneField>
    </Datagrid>
);
