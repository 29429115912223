import { Edit,Create,useCreate, useUpdate,useNotify,useRedirect,List,Show} from 'react-admin';
import { InvoiceCreateInputs,InvoiceEditInputs } from '../inputs';
import { InvoiceShowFields,InvoiceListFields } from '../fields';

let url:any;
export const grabInvoiceUrl = async (incomingUrl:any) => {
    url = incomingUrl;
    return url;
}
export const InvoiceShow = () => (
    <Show>
       <InvoiceShowFields />
    </Show>
);

export const InvoiceList = () => {
    return(
    <List>
        <InvoiceListFields />
    </List>
)};

// TODO: refactor "Save Functions"
export const InvoiceCreate = () => {
    const [create] = useCreate()
    const redirect = useRedirect()
    const notify = useNotify()
    const invoiceSave = (data:any) => {
        data["mainFile"].url = url
        create('Invoice',{data: {data}},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Facture crée avec succès');
        redirect('list','invoice');
    })
    };
    return(
        <Create redirect="show">
            <InvoiceCreateInputs invoiceSave={invoiceSave} />
        </Create>
    )
};

export const InvoiceEdit = () => {
    const [update] = useUpdate()
    const redirect = useRedirect()
    const notify = useNotify()
    const invoiceSave = (data:any) => {
        const id = data.id
        delete data.id
        delete data.customerCode
        delete data.customerName
        delete data.employeeFirstName
        delete data.employeeLastName
        delete data.tenant
        delete data.tenantCode
        delete data.tenantName
        delete data.mainFile
        // data["mainFile"].url = url
        update('Invoice',{data: {data},id:id},{returnPromise:true,onError: (error:any) => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Facture modifiée avec succès');
        redirect('list','invoice');
    })
    };
    return(
        <Edit>
            <InvoiceEditInputs invoiceSave={invoiceSave} />
        </Edit>
)};

