import { SimpleShowLayout, TextField,Datagrid } from 'react-admin';
import { rowStyle } from '../style';

export const CustomerShowFields =() => (
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="externalId" />
            <TextField source="name" />
            <TextField source="code" /> 
        </SimpleShowLayout>
);

export const CustomerListFields = () => (
    <Datagrid rowStyle={rowStyle} rowClick="show">
            <TextField source="name" />
            <TextField source="code" /> 
        </Datagrid>
)