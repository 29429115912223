import {Edit,useUpdate,Show,List,Create,useCreate,useNotify,useRedirect} from "react-admin";
import { PeriodShowFields, PeriodListFields } from "../fields";
import { PeriodFilters } from "../filters";
import { PeriodCreateInputs, PeriodEditInputs } from "../inputs";

export const PeriodShow = () => (
  <Show>
    <PeriodShowFields />
  </Show>
);

export const PeriodList = () => (
  <List filters={PeriodFilters}>
    <PeriodListFields />
  </List>
);

export const PeriodCreate = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();
  const periodSave = (data: any) => {
    create(
      "Period",
      { data: { data } },
      {
        returnPromise: true,
        onError: (error) => {
          notify((error as Error).message, { type: "error" });
        },
      }
    ).then(() => {
      notify("Période crée avec succès");
      redirect("list", "period");
    });
  };
  return (
    <Create>
      <PeriodCreateInputs periodSave={periodSave} />
    </Create>
  );
};

export const PeriodEdit = () => {
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();
  const periodSave = (data: any) => {

    const id = data.id;

    delete data["id"];
    delete data["tenant"];
    delete data["tenantName"];
    delete data["contractRate"];
    delete data["expensesIds"];
    delete data["project"];
    delete data["projectCode"];
    delete data["customerName"];
    delete data["contractEndCustomerName"]
    
    data?.expenses?.forEach((expense:any,i:number) =>
      expense !== null
      ? 
        delete data?.expenses[i]["__typename"] &&
        delete data?.expenses[i]["amount"] &&
        delete data?.expenses[i]["costVAT"] &&
        delete data?.expenses[i]["vatPercent"] &&
        delete data?.expenses[i]["vatCost"] &&
        delete data?.expenses[i]["justify"] 
      : ""
    )

    data?.earnings?.forEach((earning: any, i: number) =>
      earning !== null ? delete data?.earnings[i]["__typename"] : ""
    );

    data?.bills?.forEach((bill:any,i:number) =>
      bill !== null ? delete data?.bills[i]["__typename"] : ""
    );

    update(
      "Period",
      { data: { data }, id: id },
      {
        returnPromise: true,
        onError: (error) => {
          notify((error as Error).message, { type: "error" });
        },
      }
    ).then(() => {
      notify("Période mise à jour avec succès");
      redirect("list", "period");
    });
  };
  return (
    <Edit>
      <PeriodEditInputs periodSave={periodSave} />
    </Edit>
  );
};
