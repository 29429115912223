import { green,red } from '@mui/material/colors';

export const styles = {
    primaryBgColor: {
        backgroundColor:"rgb(16,23,96)"
    },
    secondaryBgColor: {
        backgroundColor:"rgb(250,16,105)"
    },
    primaryTextColor: {
        color:"rgb(16,23,96)",
    },
    secondaryTextColor: {
        color:"rgb(250,16,105)"
    },
    successTextColor:{
        color: green[700]
    },
    errorTextColor: {
        color: red[500]
    },
    row: {
        display: "flex",
        flexDirection: "row"
    },
    input: {

    },
    col: {
        display:"flex",
        flexDirection:"column"
    },
    center: {
        display:"flex",
        justifyContent: "center",
        alignItems:'center'
    },
    pt10: {
        paddingTop: 5
    },
    around: {
        display:"flex",
        justifyContent: "space-around"
    },
    top: {
        marginTop: 10
    },
    textCenter: {
        textAlign:"center"
    },
    card: {
        backgroundColor:"Lavender",
        marginTop: 5,
        marginBottom: 5,
        width: 300,
        height:300
    },
    dashboardCard:{margin:4,padding:2},
    textDark: {
        color: 'black'
    }, 
    textWhite: {
        color: 'white'
    }, 
    textPrimary: {
        color: 'rgb(16,23,96)'
    },
    textClear: {
        color: 'white'
    },
    textSuccess: {
        color: 'rgb(26,132,19)'
    },
    textGreen: {
        color: 'rgb(37,141,40)'
    },
    textBlue: {
        color: 'rgb(16,23,96)'
    },
    textLightred: {
        color: 'rgb(248,113,133)'
    },
    bgPrimary: {
        backgroundColor: 'rgba(16,23,96,0.7)'
    },  
    bgLightgreen: {
        backgroundColor: 'rgb(196,240,192)'
    },  
    bgLightblue: {
        backgroundColor: 'rgb(179,207,255)'
    },
    alignCenter:{alignItems:'center'},
    dashboardHeader:{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'},
    mb1:{marginBottom:1},
    mb2:{marginBottom:2},
    mb4:{marginBottom:4},
    customListTitle:{textAlign:'center',marginTop:3,marginBottom:3},
    blueHeaderCell:{"& .RaDatagrid-headerCell":{backgroundColor:'rgb(24,118,210)'}},
    redHeaderCell:{"& .RaDatagrid-headerCell":{backgroundColor:'rgb(211,48,47)'}},
    warningHeaderCell:{"& .RaDatagrid-headerCell":{backgroundColor:'rgb(237,109,3)'}},
    chips: {display:'flex',flexDirection:'column',fontWeight:'bold',alignItems:'center',alignContent:'center',alignSelf:'center',paddingTop:2,marginTop:3,overflow:'auto'},
    chipsText: {display:'flex',flexDirection:'column',overflow:'auto',textOverflow:'ellipsis',marginTop:1, width:'90px',borderRadius:2,fontWeight:'bold'},
    menuItem: {
        color:"rgb(16,23,96)",
        "&:focus": {
            backgroundColor:"rgb(250,16,105)",
            color:"white",
            fontWeight:"bold"
        }
    },
    rowStyle: {
        backgroundColor:"rgb(16,23,96)"
    },
    ecardStyle: {
        display:"flex",flexDirection:"column", justifyContent:"space-around"
    },
    lgHidden:{display:{lg:'none',md:'block',sm:'block',xs:'block'}},
    reportStatementLabelsBox:{ display: "flex", flexDirection: "column",borderTop:1,borderLeft:1,borderRight:1,textAlign:'center',alignItems:'center',justifyContent:'center'},
    reportStatementLabels:{borderBottom:1,padding:1,width:'100%'},
    reportStatementLabelsYear:{borderBottom:1,padding:1,width:'100%'},
    reportStatementValuesBox:{display:'flex',flexDirection:'column',borderTop:1,borderRight:1,textAlign:'center'},
    reportStatementValues:{borderBottom:1, padding:1},
    statementLabels:{display:'flex',justifyContent:'center',alignItems:'center',width:100,height:100,whiteSpace:'pre-wrap',overflowWrap:'break-word',padding:'5px',textAlign:'center'},
    statementLabelsBox: {border:1,display:{xs:'none',sm:'none',md:'none',lg:'block'}},
    statementValues:{whiteSpace:'pre-wrap',overflowWrap:'break-word',width:100,height:100,padding:'5px',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center',overflow:'auto'},
    statementValuesBox:{display:{xs:'none',sm:'none',md:'none',lg:'block'},borderTop:1,borderRight:1,borderBottom:1,borderColor:'rgb(16,23,96)'},
    tabs: {display:'flex',justifyContent:'center',flexDirection:'column',textAlign:'center',alignItems:'center'},
    fs1: {fontSize:'0.8rem'},
    pb20: {paddingBottom: '5rem'},
    pb4: {paddingBottom: '1rem'},
    bold:{fontWeight:'bold'},
    w250: {width:250},
    loginContainer:{backgroundColor:'rgb(16,23,96)',height:'100vh',display:'flex',justifyContent:'space-around',alignItems:'center'},
    loginImage:{backgroundColor:'white',height:'50vh',width:'40vw',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:5},
    loginForm: {backgroundColor:'white',height:'50vh',width:'40vw',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',borderRadius:5},
    loginButton:{backgroundColor:'rgb(250,16,105)',color:'white',fontWeight:'bold',marginTop:4, borderRadius:2,width:250,display:'flex',justifyContent:'center',alignItems:'center'},
    smHiddent:{display:{lg:'block',md:'none',sm:'none',xs:'none'}},
    w100:{width:'100%'},
}

// TODO: handle multiple rowStyles, periods to complete, business days etc..
export const rowStyle = (record:any,index:any) => ({
    backgroundColor:  index%2 === 0 && record.status !== 'EXPIRES' ? 'rgba(16,23,96,0.3)' : record.status === 'EXPIRES' ? 'rgb(250,16,105)' : 'white',
    alignItems:'center',textAlign:'center', 
})

export const periodsRowStyle = (record:any,index:any) => ({
    backgroundColor:  index%2 === 0 && record.employeeContributions !== 0 && record.employerContributions !== 0 && record.salary !== 0 && record.taxedSalary !== 0 ? 'rgba(16,23,96,0.3)' : record.employeeContributions === 0 && record.employerContributions === 0 && record.salary === 0 && record.taxedSalary === 0  ? 'rgb(250,16,105)' : 'white',
    alignItems:'center',textAlign:'center', 
})


