import { SimpleShowLayout,Datagrid,TextField,FileField } from 'react-admin';
import { rowStyle } from '../style';
import { EmployeeField } from './CustomFields';

export const FileShowFields = () => (
    <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="type" />
        <FileField src="mainFile.url" source="mainFile.url" title="Télécharger" download={true} />
        <EmployeeField label='Employé' />
    </SimpleShowLayout>
);

export const FileListFields = () => (
    <Datagrid rowStyle={rowStyle} rowClick="show">
        <TextField source="name" />
        <TextField source="type" />
        <FileField src="mainFile.url" source="mainFile.url" title="Télécharger" download={true} />
        <EmployeeField label='Employé' />
    </Datagrid>
);