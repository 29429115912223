import { SimpleForm,ReferenceInput,AutocompleteInput,TextInput, SelectInput, ArrayInput, SimpleFormIterator} from 'react-admin';
import { employeeRenderer } from '../renderers';
import { grabUrl } from '../screens';
import { MyFileInput } from './CustomInputs';


export const FileCreateInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues onSubmit={props.fileSave}>
            <TextInput fullWidth source="name" />
            <SelectInput fullWidth source="type" choices={[{name:"Bulletin",id:"payslip"},{name:"Contrat",id:"contract"},{name:"Modèle",id:"model"},{name:"Autre",id:"other"}]} />
            <ArrayInput fullWidth source="employee">
                <SimpleFormIterator fullWidth inline>
                        <ReferenceInput source="employee" reference="Employee">
                                <AutocompleteInput optionText={employeeRenderer} fullWidth />
                        </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
            <MyFileInput {...props} source="mainFile.url" grabUrl={grabUrl} />
    </SimpleForm>
);

export const FileEditInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues onSubmit={props.fileSave}>
            <TextInput source="name"  />
            <SelectInput source="type" choices={[{name:"Bulletin",id:"payslip"},{name:"Contrat",id:"contract"},{name:"Modèle",id:"model"},{name:"Autre",id:"other"}]} />
            <ReferenceInput source="employee" reference="Employee">
                    <AutocompleteInput optionText={employeeRenderer} fullWidth />
            </ReferenceInput>
            <MyFileInput {...props} source="mainFile" grabUrl={grabUrl} />
    </SimpleForm>
);