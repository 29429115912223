import  { NumberInput } from 'react-admin';

export const CentsInput = (props:any) => {
    return (
        <NumberInput
            options={{style:'currency',currency:'EUR'}}
            {...props}
            source={props.source}
            format={v => String(v / 100)}
            parse={v => parseFloat(v) * 100} 
        />
    );
};
