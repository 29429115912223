import { Edit,Show,List,Create,useCreate, useUpdate,useNotify,useRedirect,Button, EditButton, TopToolbar,useRecordContext } from 'react-admin';
import { EmployeeFilters } from '../filters';
import { EmployeeShowFields,EmployeeListFields } from '../fields';
import { EmployeeCreateInputs,EmployeeEditInputs } from '../inputs';
import * as icon from '../icons';

const EmployeeEditActions = () => {

    const [update] = useUpdate()
    const notify = useNotify()
    const redirect = useRedirect()
    const context = useRecordContext();

    const sync:any = () => {
        const data = {sync:true}
        update('Employee',{data: {data},id:context?.id},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
        }).then(() => {
            notify('Employé resynchronisé avec succès');
            redirect('show','employee',context?.id);
        })
    }

return(
    <TopToolbar>
        <EditButton />
        <Button color="primary" label='Resynchroniser' startIcon={<icon.SyncIcon/>} onClick={() => sync()}></Button>
    </TopToolbar>
)
};

export const EmployeeShow = () => (
    <Show actions={<EmployeeEditActions />}>
       <EmployeeShowFields />
    </Show>
);

export const EmployeeList = () => {
    return(
    <List filters={EmployeeFilters}>
        <EmployeeListFields />
    </List>
)};

export const EmployeeCreate =  () => {
    const [create] = useCreate()
    const notify = useNotify()
    const redirect = useRedirect()
    const employeeSave = (data:any) => {
        create('Employee',{data: {data}},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Employé crée avec succès');
        redirect('list','employee');
    })
    }
    
    return(
        <Create>
            <EmployeeCreateInputs employeeSave={employeeSave} />
        </Create>
    )
};

export const EmployeeEdit = () => {
    const [update] = useUpdate()
    const notify = useNotify()
    const redirect = useRedirect()
    const employeeSave:any = (datas:any) => {
        if (datas?.autoExpenses?.length !== 0) {
            datas?.autoExpenses?.forEach((expense:any,index:number) => {
                delete datas?.autoExpenses[index]?.__typename
                Object.entries(expense)?.forEach((kv:any) => {
                    if (kv[1] === '') {
                       delete expense[kv[0]]
                    }
                })
            })
        }
        const data = {
            firstName: datas.firstName,
            lastName: datas.lastName,
            email: datas.email,
            status: datas.status,
            externalId: datas.externalId,
            mooncardId: datas.mooncardId,
            user: datas.user,
            createUser: datas.createUser,
            updateUser: datas.updateUser,
            userEmail: datas.userEmail,
            userPassword:datas.userPassword,
            autoExpenses: datas.autoExpenses,
        }
        update('Employee',{data: {data},id:datas.id},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Employé modifié avec succès');
        redirect('list','employee');
    })
    }
    return(
        <Edit>
            <EmployeeEditInputs employeeSave={employeeSave} />
        </Edit>
)};

