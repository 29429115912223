import { SimpleShowLayout, TextField,Datagrid,ChipField,DateField,FunctionField, useListContext, Loading } from 'react-admin';
import { rowStyle } from '../style';
import { EmployeeField, StatusField } from './CustomFields';

export const BusinessDayShowFields =() => (
        <SimpleShowLayout>
            <TextField source="type" />
            <ChipField source="status" />
            <DateField source="dateStart" />
            <FunctionField render={(record:any) => record ? `${new Date(parseInt(record?.range.split('-')[0])).toLocaleDateString()} - ${new Date(parseInt(record?.range.split('-')[1])).toLocaleDateString()}` : ''} />
        </SimpleShowLayout>
);

export const BusinessDayListFields = () => {
    const context = useListContext();

    if (!context?.data) return <Loading />
    context?.data?.sort((a:any,b:any) => a.range.localeCompare(b.range));

    const businessDaysSortedByRange:any[] = [];

    let currentRange;
    let currentArray:any[] = [];

    for (const item of context.data) {
         if (item.range !== currentRange) {
            if(currentArray.length > 0) {businessDaysSortedByRange.push(currentArray);} 
            currentRange = item.range; currentArray = [item];
        } else {
            currentArray.push(item); 
        } 
    }
    if (currentArray.length > 0){ businessDaysSortedByRange.push(currentArray) };
    
    return(
        <>
        {businessDaysSortedByRange.length !== 0 
        ? businessDaysSortedByRange.map((businessDayArray:any,index:number) => {
            return (
                <>
                    <h2>Congés Entre le : {new Date(parseInt(businessDayArray[index]?.range.split('-')[0])).toLocaleDateString()} et le {new Date(parseInt(businessDayArray[index]?.range.split('-')[1])).toLocaleDateString()} </h2>
                    <Datagrid data={businessDayArray} rowStyle={rowStyle} rowClick={'edit'}>
                        <TextField label="Type de congé" source="type" />
                        <EmployeeField label='Employé' />
                        <StatusField source="status" />
                        <DateField source="dateStart" />
                        <FunctionField label="Intervalle" render={(record:any) => record ? `${new Date(parseInt(record?.range.split('-')[0])).toLocaleDateString()} - ${new Date(parseInt(record?.range.split('-')[1])).toLocaleDateString()}` : ''} />
                    </Datagrid>
                </>
            )
        })
        : ''}
        </>
)
};