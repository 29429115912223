import { TextInput,SelectInput,DateInput,BooleanInput,NumberInput, ReferenceInput, AutocompleteInput } from 'react-admin';

export const EmployeeFilters:any = [
    <TextInput label='Prénom' source='firstName' alwaysOn  />,
    <BooleanInput sx={{marginBottom:1.5}} label="Archivés" source='locked' alwaysOn />, 
    <TextInput label='Nom De Famille' source='lastName' />,
    <TextInput label='Adresse Email' source='email' type='email' />,
    <TextInput label='Code' source='code' />,
    <SelectInput label='Status' source='status' choices={[{id:'CREATED',name:'Crée'},{id:'DELETED',name:'Supprimé'},{id:'LOCKED',name:'Vérouillé'}]} />,
    <DateInput parse={v => Date.parse(v)} label="Date de Création" source="createdAt" />,
    <DateInput parse={v => Date.parse(v)} label="Date de Modification" source="updatedAt" />,
    <TextInput label='Identifiant' source='ids' />  
];

export const ContractFilters:any = [
    <BooleanInput label="Vérrouillé" defaultChecked source={"locked"} alwaysOn />,
    <BooleanInput label="Avenant" source={"is_amendment"} defaultChecked alwaysOn />,
    <TextInput label="Prénom de l'employé" source='employeeFirstName' />,
    <TextInput label="Nom de famille de l'employé" source='employeeLastName' />,
    <NumberInput label="Taux journalier" source="rate" />,
    <DateInput parse={v => Date.parse(v)} label="Date de début" source="dateStart" />,
    <DateInput parse={v => Date.parse(v)} label="Date de fin" source="dateEnd" />,
    <TextInput label='ids' source='ids' />  
];

export const CustomerFilters:any = [
    <TextInput label='Client' source='name' alwaysOn  />, 
    <TextInput label='Identifiant' source='ids' />,  
    <TextInput label='Ancien Identifiant' source="externalId" />,
    <TextInput label='Code' source='code' />,
    <TextInput label='Référal' source='referal' />
];

export const PayslipFilters:any = [
    <TextInput label="Prénom de l'employé " source='employeeFirstName' alwaysOn />, 
    <TextInput label='ids' source='ids' />,
    <TextInput label='Ancien Identifiant' source="externalId" />,  
    <TextInput label='Code' source='code' />,
    <TextInput label="Nom De Famille de l'employé" source='employeeLastName' />,
    <TextInput type='email' label="Adresse Mail de l'employé" source='employeeEmail' />,
    <TextInput type='phone' label="Téléphone de l'employé" source='employeePhone' />,
    <DateInput parse={v => Date.parse(v)} label="Date" source="date" />,
    <BooleanInput label='notify ?' source='notify' />
];

export const PeriodFilters:any = [
    <ReferenceInput source="employee" reference="Employee" alwaysOn  >
        <AutocompleteInput optionText="lastName" label="Employé(Nom)" fullWidth />
    </ReferenceInput>,
    <TextInput label='Nom du Contrat' source='contractName' />,
    <TextInput label='Nom du Client' source='customerName' />,
    <NumberInput label='Nombre de jours' source='workedDay' />,
    <DateInput parse={v => Date.parse(v)} label="Date de début" source="dateStart" />,
    <DateInput parse={v => Date.parse(v)} label="Date de fin" source="dateEnd" />,
];

export const ProjectFilters:any = [
    <TextInput label='client' source='customerName' alwaysOn />,
    <TextInput label='code' source='code' />,
    <TextInput label='ids' source='ids' />  
];

export const FilesFilters:any = [
    <SelectInput alwaysOn label='Type' source='type' choices={[{id:'payslip',name:'Bulletin de salaire'},{id:'contract',name:'Contrat'},{id:'model',name:"Modèle"},{id:'other',name:'Autre'}]} />,
    <TextInput label='Nom du Fichier' source='name' />,
    <TextInput label='ID' source='ids' />  
];

export const ReportsFilters:any = [
    <TextInput label='ID' source='ids' /> , 
    <TextInput label="id" source="id" />,
    <DateInput label="Date de création" source="createdAt" />,
    <DateInput label="Début" source="dateStart" />,
    <DateInput label="Fin" source="dateEnd" />,
    <TextInput label="Type" source="type"/>,
    <TextInput label="Périodicité" source="periodicity"/>,
];