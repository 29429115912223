import {Edit,useUpdate,Show,List,Create,useCreate,useRedirect,useNotify } from 'react-admin';
import { PayslipFilters } from '../filters';
import { PayslipShowFields, PayslipListFields } from '../fields';
import { PayslipCreateInputs,PayslipEditInputs } from '../inputs';


let url:any;
export const grabPayslipUrl = async (incomingUrl:any) => {
    url = incomingUrl;
    return url;
}

export const PayslipShow = () => (
    <Show>
        <PayslipShowFields />
    </Show>
);

export const PayslipList = () => (
    <List filters={PayslipFilters}>
        <PayslipListFields />
    </List>
);

export const PayslipCreate = () => {
    const [create] = useCreate()
    const redirect = useRedirect()
    const notify = useNotify()
    const payslipSave = (data:any) => {
        data["mainFile"].url = url
        create('Payslip',{data: {data}},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Bulletin crée avec succès');
        redirect('list','payslip');
    })
    }
    return(
    <Create>
        <PayslipCreateInputs payslipSave={payslipSave} />
    </Create>)
    
};

export const PayslipEdit = () => {
    const [update] = useUpdate()
    const redirect = useRedirect()
    const notify = useNotify()
    const payslipSave:any = (data:any) => {
        data.mainFile = { url: data.mainFile.url }
        update('Payslip',{data: {data},id:data.id},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Bulletin modifié avec succès');
        redirect('list','payslip');
    })
    }
    return(
        <Edit>
           <PayslipEditInputs payslipSave={payslipSave} />
        </Edit>
)};
