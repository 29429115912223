import { useRecordContext, Link, useUpdate, useNotify, useRedirect,Confirm } from "react-admin";
import { Button } from '@mui/material';
import { useState } from "react";

export const CreateAmendmentButton = () => {
    const record = useRecordContext();
    const params = `{"dateStart":${record.dateEnd},"rate":${record.rate * 100},
        "locked":${record.locked},"is_amendment":true,
        "billingMode":"${record.billingMode}","customer":"${record.customer}",
        "employee":"${record.employee}","project":"${record.project}",
        "externalId":"${record.externalId}"}
    `;
    return(<Button sx={{fontWeight:"bold",fontSize:9}} variant="contained" disabled={record.is_amendment === false ? false : true} component={Link} to={`/contract/create?source=${params}`}>+ Créer un Avenant</Button>)
};

export const ToCompletedButton = () => {
    const record = useRecordContext();
    const [update] = useUpdate();
    const notify = useNotify();

    const contractSave = (datas:any) => {
        if (record?.status === 'En cours' || record?.status === 'EXPIRE BIENTÔT') {
              update('Contract',{data: {data:{status:datas.status}},id:datas.id});
        } else {
            notify('Impossible de passer le contrat du statut: '+ record?.status + ' à FINI'); 
        }
    }
 
    return(<Button color='success' sx={{fontWeight:"bold",fontSize:9}} variant="contained" disabled={record.status === "FINI" ? true : false} onClick={() => contractSave(record.status !== 'FINI' ? {id:record.id,status: 'COMPLETED'} : {id:record.id, status:'ONGOING' })}>Marquer comme fini</Button>)
};

export const ToArchiveButton = () => {
    const record = useRecordContext();
    const [update] = useUpdate();
    const [open,setOpen] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const redirect = useRedirect();
    const notify = useNotify();

    const employeeSave = (datas:any) => {
        setIsLoading(true)
        if (record?.status === 'CRÉE') {
              update('Employee',{data: {data:{status:datas.status}},id:datas.id},{returnPromise:true,onError: error => {
                notify((error as Error).message, {type:'error'})
              }})
              .then(() => {
                notify('Employé archivé avec succès');
                setIsLoading(false)
                redirect('list','employee');
            });
        } else {
            notify('Impossible de passer l\'employé du statut: '+ record?.status + ' à ARCHIVÉ'); 
            setIsLoading(false)
        }
    }

    return (
        <>
            <Button color='primary' sx={{fontWeight:"bold",fontSize:9}} variant="contained" disabled={record.status === "ARCHIVÉ" ? true : false} onClick={() => setOpen(true)}>Archiver l'employé</Button>
            <Confirm isOpen={open} loading={isLoading} title={`L'employé ${record && record.firstName + ' ' + record.lastName} sera archivé` } content="Êtes vous sûr de vouloir archiver l'employé ?" onConfirm={() => employeeSave(record.status !== 'Vérrouillé' ? {id:record.id,status: 'LOCKED'} : {id:record.id, status:'ONGOING' })} onClose={() => setOpen(false)} />
        </>
    )
};