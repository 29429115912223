import { Box } from '@mui/material';
import { FilterForm, FilterButton, CreateButton } from 'react-admin';

const ListToolbar = (filters:any) => {
    return(
    <Box>
        <FilterForm filters={filters.filters} />
        <Box>
            <FilterButton filters={filters.filters} />
            <CreateButton />
        </Box>
    </Box>
)};

export default ListToolbar;