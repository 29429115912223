import { Datagrid,DateField,ReferenceManyField,Tab,TabbedShowLayout,EmailField, TextField,useTranslate } from 'react-admin';
import { PeriodListFields,PayslipListFields, StatementListFields,InvoiceListFields,BusinessDayListFields, EreportsListFields } from '../fields';
import { Typography } from '@mui/material';
import  {CentsField, StatusField }  from './CustomFields';
import { rowStyle,styles } from '../style';
import * as icon from '../icons';
import { EmployeeInfosCards, ToArchiveButton } from '../components';

const actualYear = new Date().getFullYear();
const pastYears = [actualYear,actualYear-1,actualYear-2,actualYear-3,actualYear-4,actualYear-5];

export const EmployeeShowFields = () => {
    const t = useTranslate();

    return(
    <TabbedShowLayout>
    <Tab icon={<icon.InfosIcon/>} label={t('ra.categories.infos')}>
        <EmployeeInfosCards />
    </Tab>
    <Tab icon={<icon.PeriodIcon/>} label={t('ra.categories.periods')}>
        <ReferenceManyField reference='Period' target='employee' label={t('ra.categories.periods')}>
            <PeriodListFields />
        </ReferenceManyField>
    </Tab>
    <Tab icon={<icon.StatementIcon/>} label={t('ra.categories.statements')}>
        <TabbedShowLayout syncWithLocation={false}>
            { pastYears?.map((v,i) => 
                 <Tab key={i} label={`${v}`}>
                 <ReferenceManyField filter={{year: v}} reference='Statement' target='employee' label={`${t('ra.title.statementForYear')} ${v}`}>
                    <Typography>{t('ra.field.employee')} : <TextField source="firstName" /></Typography>
                     <StatementListFields />
                 </ReferenceManyField>
                </Tab>
            ) }
        </TabbedShowLayout>
    </Tab>
    <Tab icon={<icon.BusinessDayIcon/>} label={t('ra.categories.businessDays')}>
        <TabbedShowLayout syncWithLocation={false}>
            { pastYears.map((v,i) => 
                 <Tab key={i} label={`${v}`}>
                 <ReferenceManyField reference='BusinessDay' target='employee' label={t('ra.categories.businessDays')}>
                     <BusinessDayListFields />
                 </ReferenceManyField>
                </Tab>
            ) }
        </TabbedShowLayout>
    </Tab>
    <Tab icon={<icon.PayslipIcon/>} label={t('ra.categories.payslips')}>
        <ReferenceManyField reference='Payslip' target='employee' label={t('ra.categories.payslips')}>
            <PayslipListFields />
        </ReferenceManyField>
    </Tab>
    <Tab icon={<icon.InvoicesIcon/>} label={t('ra.categories.invoices')}>
        <TabbedShowLayout syncWithLocation={false}>
            { pastYears.map((v,i) => 
                 <Tab key={i} label={`${v}`}>
                 <ReferenceManyField reference='Invoice' target='employee' label={`${t('ra.title.invoicesForYear')} ${v}`}>
                    <Typography>{t('ra.field.employee')} : <TextField source="firstName" /></Typography>
                     <InvoiceListFields />
                 </ReferenceManyField>
                </Tab>
            ) }
        </TabbedShowLayout>
    </Tab>
    <Tab icon={<icon.EreportsIcon/>} label={t('ra.categories.ereport')}>
        <TabbedShowLayout syncWithLocation={false}>
            { pastYears.map((v,i) => 
                 <Tab key={i} label={`${v}`}>
                 <ReferenceManyField reference='Ereport' target='employee' label={`${t('ra.title.ereportsForYear')} ${v}`}>
                    <Typography>{t('ra.field.employee')} : <TextField source="firstName" /></Typography>
                     <EreportsListFields />
                 </ReferenceManyField>
                </Tab>
            ) }
        </TabbedShowLayout>
    </Tab>
</TabbedShowLayout>
)};



export const EmployeeListFields = () => {
    return(
    <Datagrid rowStyle={rowStyle} rowClick={"show"}>
    <TextField source="firstName" color={"rgb(16,23,96)"}  />
    <TextField source="lastName" color={"rgb(16,23,96)"}  />
    <EmailField source="email" color="rgb(250,16,105)" />
    <CentsField style={styles.bold} source="statAccountAmount" color={"rgb(16,23,96)"}    />
    <CentsField style={styles.bold}  source="statGlobalBilledAmount" color={"rgb(16,23,96)"}   />
    <CentsField style={styles.bold}  source="statExpensesOfMonth" color={"rgb(16,23,96)"}  />
    <StatusField source="status" />
    <DateField source='createdAt' />
    <DateField source='updatedAt' />
    <ToArchiveButton />
</Datagrid>
)}