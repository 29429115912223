import { StatsTable } from '@g8wdev/react-ui/lib/StatsTable';
import { Loading,useTranslate,useGetList } from "react-admin";
import { Box, Tab,Tabs,Card } from '@mui/material';
import { TabPanel,a11yProps } from '../screens';
import { styles } from '../style';
import { useState } from 'react';
import * as icon from '../icons';

const actualYear = new Date().getFullYear();
const pastYears = [actualYear,actualYear-1,actualYear-2,actualYear-3,actualYear-4,actualYear-5];
const type = "GLOBAL"

export const GlobalReport = () => {
    
    const { data } = useGetList('Report')
    const t = useTranslate();
    const [value, setValue] = useState(0);
    const handleChange = (event:any,newValue:number) => {setValue(newValue)};

    if (!data) return <Loading />;
    if (data.length === 0) return <Box>{t('ra.messages.noReports')}</Box>
    
    return (
        <Box sx={styles.tabs}>
            <Tabs value={value} onChange={handleChange}>
                {pastYears.map((year:number,index:number) =>
                    <Tab key={index} label={<Box sx={[styles.around,styles.alignCenter]}><icon.ReportsIcon/>{year}</Box>} {...a11yProps(index)} />
                )}
            </Tabs>
            {pastYears.map((year:number,index:number) => 
                <TabPanel key={index} value={value} index={index}>
                    <Card sx={[{padding:2,borderRadius:5}]}>
                        <StatsTable  data={data.filter((report:any) => report.year === year)} type={type} year={year} />
                    </Card>
                </TabPanel>
            )}
        </Box>

    )
}