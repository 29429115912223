import { Box, Typography } from "@mui/material";
import { Datagrid, DateField, FunctionField, Loading,TextField } from "react-admin";
import { EmployeeField } from "../fields";
import { BusinessDayIcon, ContractIcon,PeriodIcon } from "../icons";
import { rowStyle,styles } from "../style";

const sort = { field: 'id', order: 'DESC' };

export const BusinessDaysToValidateList = ({data,isLoading}:any) => {
    if (isLoading) return <Loading />
    return (
        <Box sx={styles.mb4}>
            <Typography sx={styles.customListTitle} variant='h4'><BusinessDayIcon /> Congés à valider {`(${data?.businessDaysToValidate?.length})`}</Typography>
            <Datagrid sx={styles.blueHeaderCell} resource='businessDay' rowClick={'edit'} rowStyle={rowStyle} sort={sort} data={data?.businessDaysToValidate} isLoading={isLoading}>
            <EmployeeField label='Employé' />
            <TextField label='Type' source='type' />
            <DateField label='Date de début' source='dateStart' />
            <FunctionField label="Intervalle" render={(record:any) => record ? `${new Date(parseInt(record?.range?.split('-')[0])).toLocaleDateString()} - ${new Date(parseInt(record?.range?.split('-')[1])).toLocaleDateString()}` : ''} />
            </Datagrid>
        </Box>
    )
}

export const ContractsAboutToExpiresList = ({data,isLoading}:any) => {
    if (isLoading) return <Loading />
    return (
        <Box sx={styles.mb4}>
            <Typography sx={styles.customListTitle} variant='h4'><ContractIcon /> Contrats arrivant à expiration {`(${data?.contractsNearEnd?.length})`}</Typography>
            <Datagrid sx={styles.redHeaderCell} resource='contract' rowClick={'edit'} rowStyle={rowStyle} sort={sort} data={data?.contractsNearEnd} isLoading={isLoading}>
                <FunctionField label='Employé' render={(record:any) => `${record.employeeFirstName} ${record.employeeLastName}`} />
                <TextField source='customerName' label='Client' />
                <DateField source='dateStart' label="Date de début" />
                <DateField source='dateEnd' label="Date de fin" />
            </Datagrid>
        </Box>
    )
}

export const PeriodsToCompleteList = ({data,isLoading}:any) => {
    if (isLoading) return <Loading />
    return (
        <Box sx={styles.mb4}>
           <Typography sx={styles.customListTitle} variant='h4'><PeriodIcon /> Périodes à compléter {`(${data?.periodsToComplete?.length})`} </Typography>
            <Datagrid sx={styles.warningHeaderCell} resource='period' rowClick={'edit'} rowStyle={rowStyle} sort={sort} data={data?.periodsToComplete} isLoading={isLoading}>
                <EmployeeField label='Employé' />
                <TextField source='contractName' label='Contrat' />
                <DateField source='dateStart' label="Date de début" />
                <DateField source='dateEnd' label="Date de fin" />
            </Datagrid>
        </Box>
    )
}
