import { SimpleForm,TextInput,required } from 'react-admin';

export const CustomerCreateInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.customerSave}>
            <TextInput source='name'  validate={[required()]} fullWidth />
            <TextInput source='externalId' fullWidth />
        </SimpleForm>
);

export const CustomerEditInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.customerSave}>
            <TextInput source='name'  validate={[required()]} fullWidth />
            <TextInput source='externalId' fullWidth />
        </SimpleForm>
);