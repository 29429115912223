import { Box,Card } from '@mui/material';
import { SimpleForm,ReferenceInput,AutocompleteInput, TextInput, DateInput,NumberInput,BooleanInput, FileInput,ArrayInput,SimpleFormIterator} from 'react-admin';
import { CentsInput } from './NumberInput';
import { styles } from '../style';
import { contractRenderer, employeeRenderer } from '../renderers';

export const PeriodCreateInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.periodSave}>
    <ReferenceInput source="project" reference="Project">
        <AutocompleteInput optionText="name" fullWidth />
    </ReferenceInput>
    <ReferenceInput source="contract" reference="Contract">
        <AutocompleteInput optionText={contractRenderer} fullWidth isRequired />
    </ReferenceInput>
    <ReferenceInput source="employee" reference="Employee">
        <AutocompleteInput optionText={employeeRenderer} fullWidth isRequired />
    </ReferenceInput>
    <Card sx={[styles.alignCenter,styles.row,styles.w100,styles.around,styles.mb2]}>
        <DateInput parse={v => Date.parse(v)} source="dateStart" defaultValue={"date"} isRequired   />
        <DateInput parse={v => Date.parse(v)} source="dateEnd" defaultValue={"date"}   isRequired />
        <NumberInput source="workedDay"  isRequired />
    </Card>
    <Card sx={[styles.around,styles.w100,styles.mb2]}>
        <NumberInput source="periodPercent" isRequired />
        <CentsInput source="internalFees"  />
        <CentsInput source="incomeTaxes" />
        <CentsInput source="commercialFee"  />
    </Card>
    <Card sx={[styles.around,styles.w100,styles.mb2]}>
        <CentsInput source="periodTurnover" isRequired/>
        <CentsInput source="taxedSalary" />
        <CentsInput source="salary" />
    </Card>
    <Card sx={[styles.around,styles.w100,styles.mb2]}>
        <CentsInput source="employerContributions"/>
        <CentsInput source="employeeContributions"/>
    </Card>
    <Card sx={[styles.around,styles.w100,styles.mb2]}>
        <CentsInput source="peeAmount" />
        <CentsInput source="perAmount" />
    </Card>
    <ArrayInput source="expenses">
        <SimpleFormIterator inline>
            <TextInput source="name" />
            <CentsInput source="cost" />
        </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput source="earnings">
        <SimpleFormIterator inline>
            <TextInput source="label" />
            <CentsInput source="amount" />
        </SimpleFormIterator>
    </ArrayInput>
    <FileInput source="craUrl" />
    <FileInput source="payslipUrl" />
    <BooleanInput source="locked" />
</SimpleForm>
);

export const PeriodEditInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.periodSave}>
        <Box sx={[styles.row,styles.w100]}>
            <TextInput fullWidth source="customerName" disabled />
            <TextInput fullWidth source="contractName" disabled />
        </Box>
        <Box sx={[styles.row,styles.w100]}>
            <DateInput fullWidth parse={v => Date.parse(v)} source="dateStart" defaultValue={"date"}  />
            <DateInput fullWidth parse={v => Date.parse(v)} source="dateEnd" defaultValue={"date"}  />
        </Box>
        <ReferenceInput source="employee" reference="Employee">
            <AutocompleteInput optionText={employeeRenderer} fullWidth disabled />
        </ReferenceInput>
        <Card sx={[styles.alignCenter,styles.row,styles.w100,styles.around,styles.mb2]}>
        <DateInput parse={v => Date.parse(v)} source="dateStart" defaultValue={"date"} isRequired   />
        <DateInput parse={v => Date.parse(v)} source="dateEnd" defaultValue={"date"}   isRequired />
        <NumberInput source="workedDay"  isRequired />
    </Card>
    <Card sx={[styles.around,styles.w100,styles.mb2]}>
        <NumberInput source="periodPercent" isRequired />
        <CentsInput source="internalFees"  />
        <CentsInput source="incomeTaxes" />
        <CentsInput source="commercialFee"  />
    </Card>
    <Card sx={[styles.around,styles.w100,styles.mb2]}>
        <CentsInput source="periodTurnover" isRequired/>
        <CentsInput source="taxedSalary" />
        <CentsInput source="salary" />
    </Card>
    <Card sx={[styles.around,styles.w100,styles.mb2]}>
        <CentsInput source="employerContributions"/>
        <CentsInput source="employeeContributions"/>
    </Card>
    <Card sx={[styles.around,styles.w100,styles.mb2]}>
        <CentsInput source="peeAmount" />
        <CentsInput source="perAmount" />
    </Card>
            <ArrayInput source="expenses">
                <SimpleFormIterator inline>
                    <TextInput source="name" />
                    <CentsInput source="cost" />
                    <BooleanInput source="display" />
                </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput source="earnings">
        <SimpleFormIterator inline>
            <TextInput source="label" />
            <CentsInput source="amount" />
        </SimpleFormIterator>
    </ArrayInput>
    </SimpleForm>
);