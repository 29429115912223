import { SimpleForm,DateInput,BooleanInput,ReferenceInput,TextInput,AutocompleteInput, SelectInput } from 'react-admin';
import { employeeRenderer } from '../renderers';
import { CentsInput } from './NumberInput';

export const ContractCreateInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.contractSave}>
            <DateInput parse={v => Date.parse(v)} source="dateStart" fullWidth required />
            <DateInput parse={v => Date.parse(v)} source="dateEnd" fullWidth required />
            <CentsInput source="rate" fullWidth required />
            <BooleanInput source="locked" />
            <BooleanInput source="is_amendment" />
            <SelectInput source='billingMode' fullWidth choices={[{id:"rate",name:"TJM"},{id:"invoice",name:"Factures/Devis"}]} />
            <ReferenceInput source="customer" reference="Customer"  >
                <AutocompleteInput optionText="name" fullWidth  isRequired />
            </ReferenceInput>
            <TextInput source='endCustomerName' fullWidth />
         <ReferenceInput sort={{field:'lastName',order:'ASC'}} source="employee" reference="Employee">
                <AutocompleteInput label='Employé' optionText={employeeRenderer} fullWidth isRequired />
            </ReferenceInput>
            <ReferenceInput source="project" reference="Project">
                <AutocompleteInput optionText="name" fullWidth isRequired />
            </ReferenceInput>
            <ReferenceInput source="externalId" reference="Contract">
             <AutocompleteInput optionText="externalId" fullWidth />
            </ReferenceInput>
</SimpleForm>
);

export const ContractEditInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.contractSave}>
            <DateInput parse={v => Date.parse(v)} source="dateStart" fullWidth  />
            <DateInput parse={v => Date.parse(v)} source="dateEnd" fullWidth  />
            <CentsInput source="rate" fullWidth  />
            <BooleanInput source="locked" />
            <BooleanInput label="is_amendment ?" source="is_amendment" />
            <SelectInput source='billingMode' fullWidth choices={[{id:"rate",name:"TJM"},{id:"invoice",name:"Factures/Devis"}]} />
            <ReferenceInput reference="Customer" source="customer">
                <AutocompleteInput optionText="name" fullWidth   />
            </ReferenceInput>
            <TextInput source='endCustomerName' />
            <ReferenceInput source="project" reference="Customer"  >
                <AutocompleteInput optionText="project" fullWidth   />
            </ReferenceInput>
            <ReferenceInput source="employee" reference="Employee" >
                <AutocompleteInput optionText="firstName" fullWidth  />
            </ReferenceInput>
            <ReferenceInput source="employee" reference="Employee">
                <AutocompleteInput optionText="lastName" fullWidth  />
            </ReferenceInput>
            <ReferenceInput source="externalId" reference="Contract">
             <AutocompleteInput optionText="externalId" fullWidth />
            </ReferenceInput>
           <SelectInput source='status' fullWidth choices={[{id:"CREATED",name:"Crée"},{id:"ONGOING",name:"En cours"},{id:"EXPIRES",name:"Arrive à expiration"},{id:"COMPLETED",name:"Fini"},{id:"LOCKED",name:"Archivé"}]} />
    </SimpleForm>
)