import { Edit,useUpdate,Show,List,Create,useCreate,useNotify,useRedirect} from 'react-admin';
import { CustomerFilters } from '../filters';
import { CustomerShowFields, CustomerListFields } from '../fields';
import { CustomerCreateInputs, CustomerEditInputs } from '../inputs';
export const CustomerShow = () => (
    <Show>
        <CustomerShowFields />
    </Show>
);

export const CustomerList = () => (
    <List filters={CustomerFilters}>
        <CustomerListFields />
    </List>
);

export const CustomerCreate =  (props:any) => {
    const [create] = useCreate()
    const notify = useNotify();
    const redirect = useRedirect();

    const customerSave = (data:any) => {
        create('Customer',{data: {data}},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Client crée avec succès');
        redirect('list','customer');
    })
    };
    
    return(
    <Create>
       <CustomerCreateInputs customerSave={customerSave} />
    </Create>)
    
};

export const CustomerEdit = () => {
    const [update] = useUpdate()
    const notify = useNotify();
    const redirect = useRedirect();
    const customerSave:any = (datas:any) => {
        const data = {
            name: datas.name,
            externalId: datas.externalId
        }
        update('Customer',{data: {data},id:datas.id},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Client modifié avec succès');
        redirect('list','customer');
    })
    }
    return(
    <Edit>
        <CustomerEditInputs customerSave={customerSave} />
    </Edit>
)};
