import { SimpleShowLayout,TextField,EmailField,DateField,UrlField,ImageField } from 'react-admin';
import { Box,Divider,Typography,Card,CardContent,Chip } from '@mui/material';
import { styles } from '../style';
import { LinkedIn,Twitter,Facebook,YouTube,Instagram,Pinterest,FlagTwoTone,LocationCity,MyLocation,MailOutlineTwoTone,Phone,Language } from '@mui/icons-material';

export const OrganizationShowFields = () => (
    <SimpleShowLayout>
        <Card sx={styles.ecardStyle}>
        <Divider variant="middle">Général</Divider>
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Nom de l'organisation</Typography>
                    <Chip color="primary" sx={styles.textCenter} variant="outlined" label={<TextField source="name" />}/>
                </Box>
                <Box sx={styles.textCenter}>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Code</Typography>
                    <Chip color="primary" variant="outlined" label={<TextField source="code" />} />
                </Box>
            </CardContent>
            <Divider variant="middle" />
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Propriétaire</Typography>
                    <Chip sx={[styles.primaryTextColor]} variant="outlined" label={ <div><TextField source="ownerLastName" /> <TextField source="ownerFirstName" /></div>} />
                </Box>
                <Box sx={styles.textCenter}>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Email du propriétaire <MailOutlineTwoTone/></Typography>
                    <Chip sx={styles.successTextColor} variant="outlined" label={<TextField source="ownerEmail" />} />
                </Box>
                <Box sx={styles.textCenter}>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Site Web <Language /></Typography>
                    <Chip sx={styles.errorTextColor} variant="outlined" label={<UrlField source="websiteUrl"   />} />
                </Box>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <Divider variant="middle" >Statuts</Divider>
            <CardContent sx={styles.around}>
              <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Crée le</Typography>       
                   <Chip sx={[styles.primaryTextColor]} variant="outlined" label={<DateField label="Crée le : " source="createdAt" />}/>
              </Box>
              <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Modifié le :</Typography>
                   <Chip sx={styles.successTextColor} variant="outlined" label={<DateField label="Modifié le : " source="updatedAt" />} />
              </Box>
              <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Statut :</Typography>
                   <Chip sx={styles.errorTextColor} variant="outlined" label={<TextField label="Statut : " source="status" />} />
              </Box>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <Divider variant="middle" >Informations Légales</Divider>
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Pays <FlagTwoTone /></Typography>
                     <Chip sx={[styles.primaryTextColor]} variant="outlined" label={<TextField source="country" />}/>
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Ville & code postal <LocationCity /></Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<div><TextField source="city" /> <TextField source="zipCode" /> </div>} />         
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Adresse <MyLocation /></Typography>
                     <Chip sx={[styles.primaryTextColor]} variant="outlined" label={<TextField source="street" />}/>
                </Box>
            </CardContent>
            <Divider variant="middle" />
            <CardContent sx={styles.center}>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Nom Légal</Typography> 
                     <Chip sx={styles.errorTextColor} variant="outlined" label={<TextField source="legalName" color={"rgb(16,23,96)"}  />} />
                </Box>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <Divider variant="middle" >Contact</Divider>
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Email de notification <MailOutlineTwoTone/></Typography>
                     <Chip sx={[styles.primaryTextColor]} variant="outlined" label={ <EmailField source="notifyEmail" />}/>
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Email du service paye <MailOutlineTwoTone/></Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<TextField  source="mail_notification_paye" />} />
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Numéro de notification <Phone /></Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<TextField  source="notifyPhone" />} />
                </Box>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <Divider variant="middle" >Réseaux sociaux</Divider>
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}><Twitter/></Typography>
                     <Chip sx={[styles.primaryTextColor]} variant="outlined" label={ <UrlField source="twitterUrl" />}/>
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}><Facebook/></Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<UrlField  source="facebookUrl" />} />
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}><Instagram/></Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<UrlField  source="instagramUrl" />} />
                </Box>
            </CardContent>
            <Divider variant="middle" />
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}><LinkedIn/></Typography>
                     <Chip sx={[styles.primaryTextColor]} variant="outlined" label={ <UrlField source="linkedinUrl" />}/>
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}><YouTube/></Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<UrlField  source="youtubeUrl" />} />
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}><Pinterest/></Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<UrlField  source="pinterestUrl" />} />
                </Box>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <Divider variant="middle" >Logos & Images</Divider>
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Image Principale</Typography>
                     <Chip sx={[styles.primaryTextColor]} variant="outlined" label={ <ImageField source="mainImage.url" />}/>
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Logo</Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<ImageField  source="logoImage" />} />
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Fond d'écran</Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<ImageField  source="backgroundImage" />} />
                </Box>
            </CardContent>
        </Card>
    </SimpleShowLayout>
);
