import { Box, Card, Divider, CardContent, Typography, Chip } from "@mui/material"
import { TextField, DateField, FunctionField } from "react-admin"
import { CentsField } from "../fields"
import { styles } from "../style"

export const EmployeeInfosCards = () => {
    return (
        <Box>
        <Card sx={styles.ecardStyle}>
        <Divider variant="middle">Général</Divider>
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Nom & Prénom</Typography>
                    <Chip color="primary" variant="outlined" label={<Box sx={styles.textCenter}><TextField label="Prénom" source="lastName" /> <TextField label="Nom" source="firstName" /></Box>} />
                </Box>
                <Box sx={styles.textCenter}>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Email</Typography>
                    <Chip color="primary" variant="outlined" label={<TextField source="email" />} />
                </Box>
            </CardContent>
            <Divider variant="middle" />
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Compte de réserve</Typography>
                    <Chip sx={[styles.primaryTextColor]} variant="outlined" label={ <CentsField source="statAccountAmount"   />} />
                </Box>
                <Box sx={styles.textCenter}>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>CA Cumulé</Typography>
                    <Chip sx={styles.successTextColor} variant="outlined" label={<CentsField source="statGlobalBilledAmount"   />} />
                </Box>
                <Box sx={styles.textCenter}>
                    <Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Dépenses du mois</Typography>
                    <Chip sx={styles.errorTextColor} variant="outlined" label={<CentsField source="statExpensesOfMonth"   />} />
                </Box>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <Divider variant="middle" >Statuts</Divider>
            <CardContent sx={styles.around}>
              <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Crée le</Typography>       
                   <Chip sx={[styles.primaryTextColor]} variant="outlined" label={<DateField label="Crée le : " source="createdAt" />}/>
              </Box>
              <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Modifié le :</Typography>
                   <Chip sx={styles.successTextColor} variant="outlined" label={<DateField label="Modifié le : " source="updatedAt" />} />
              </Box>
              <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Statut :</Typography>
                   <Chip sx={styles.errorTextColor} variant="outlined" label={<TextField label="Statut : " source="status" />} />
              </Box>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Identifiant Employé</Typography>
                     <Chip sx={[styles.primaryTextColor]} variant="outlined" label={<TextField label="Identifiant employé : " source="id" />}/>
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Identifiant Utilisateur</Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<TextField label="Identifiant utilisateur : " source="user" />} />         
                </Box>
            </CardContent>
            <Divider variant="middle" />
            <CardContent sx={styles.center}>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Code</Typography> 
                     <Chip sx={styles.errorTextColor} variant="outlined" label={<TextField source="code" color={"rgb(16,23,96)"}  />} />
                </Box>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <CardContent sx={styles.around}>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Ancien Identifiant</Typography>
                     <Chip sx={[styles.primaryTextColor]} variant="outlined" label={ <TextField source="externalId" />}/>
                </Box>
                <Box sx={styles.textCenter}><Typography variant="subtitle2" sx={[styles.primaryTextColor,styles.textCenter]}>Identifiant Mooncard</Typography>
                     <Chip sx={styles.successTextColor} variant="outlined" label={<TextField  source="mooncardId" />} />
                </Box>
            </CardContent>
        </Card>
        <Card sx={styles.ecardStyle}>
            <Divider variant="middle" >Dépenses Automatiques</Divider>
            <CardContent sx={styles.around}>
                <Box sx={styles.center}>
                    <FunctionField label="Dépenses" render={(record:any) => record?.autoExpenses?.length === 0 ? 'pas de données' : record?.autoExpenses?.map((expense:any) => expense.name !== undefined ? <Chip sx={{marginRight:1}} color='error' label={<Typography>{expense.name} - { expense.amount / 100 }€</Typography>} /> : '')} />
                </Box>
            </CardContent>
        </Card>
        </Box>
    )
}