import { SimpleShowLayout,TextField,Datagrid } from "react-admin";
import { rowStyle } from '../style';
import { CustomerField } from "./CustomFields";

export const ProjectShowFields = () => (
    <SimpleShowLayout>
        <TextField source="name" />
        <CustomerField label='Client' />
        <TextField source="code" />
    </SimpleShowLayout>
);
export const ProjectListFields = () => (
    <Datagrid rowStyle={rowStyle} rowClick="show">
        <TextField source="name" />
        <CustomerField label='Client' />
        <TextField source="code" />
    </Datagrid>
);