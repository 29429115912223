import { WorkHistoryTwoTone,DashboardTwoTone,AssignmentInd,Business,BackupTable,ReceiptLong,InfoRounded,ListAltTwoTone,AccessTimeTwoTone,HandshakeTwoTone,BadgeTwoTone,ReceiptTwoTone,BarChartTwoTone,FileCopyTwoTone,BeachAccessTwoTone,Person,Group,Sync } from '@mui/icons-material';

export const EmployeeIcon = BadgeTwoTone,
      ContractIcon = HandshakeTwoTone,
      CustomerIcon = AssignmentInd,
      PayslipIcon = ReceiptLong,
      PeriodIcon = AccessTimeTwoTone,
      ProjectIcon = ListAltTwoTone,
      StatementIcon = BackupTable,
      InfosIcon = InfoRounded,
      OrganizationIcon = Business,
      FilesIcon = FileCopyTwoTone,
      ReportsIcon = BarChartTwoTone,
      InvoicesIcon = ReceiptTwoTone,
      BusinessDayIcon = BeachAccessTwoTone,
      StatsIcon = BarChartTwoTone,
      DashboardIcon = DashboardTwoTone,
      EreportsIcon = WorkHistoryTwoTone,
      PersonIcon = Person,
      GroupIcon = Group,
      SyncIcon = Sync
;