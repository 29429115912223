import { TextField, NumberField, SimpleShowLayout,useListContext } from 'react-admin';
import { CircularProgress, Typography,Box } from '@mui/material';
import { CentsField } from './CustomFields';
import { Statement } from '@g8wdev/react-ui/';
import { StatementDownloadButton } from '@g8wdev/react-ui/lib/atoms';
import { styles } from '../style';

export const StatementListFields = () => {
    const { data } = useListContext();   

    if (!data) return (<CircularProgress />);
    if (data.length === 0) return (<Typography sx={styles.textCenter}>No data</Typography>)

    return (
            <Box>
                <StatementDownloadButton url={data[0]['downloadUrl']} />
                <Statement data={data} />
            </Box>
    )
}

export const StatementShowFields = () => (
    <SimpleShowLayout>
        <TextField source="period" />
        <TextField source="contractName" />
        <NumberField source="workedDay" options={{style:'unit',unit:'day'}} />
        <CentsField source="baseAccount" />
        <CentsField source="periodRate" />
        <CentsField source="periodTurnover" />
        <CentsField source="taxedSalary" />
        <CentsField source="salary" />
        <CentsField source="employerContributions" />
        <CentsField source="employeeContributions" />
        <CentsField source="incomeTaxes" />
        <CentsField source="periodPercent"  options={{ style: 'percent'}}  />
        <CentsField source="gwFee" />
        <CentsField source="internalFees" />
        <CentsField source="peeAmount" />
        <CentsField source="perAmount" />
        <TextField source="other" />
    </SimpleShowLayout>
);