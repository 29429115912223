import { Box,Button,TextField,Link } from '@mui/material';
import { Loading } from 'react-admin';
import { useState,useEffect } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { styles } from './style';
import { LockTwoTone } from '@mui/icons-material';

import logo from "../src/logo-GC.png";

export const MyLoginPage = ({ theme }:any) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitting,setIsSubmitting] = useState(false);
    const [logoImg, setLogoImg] = useState(logo)
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = (e:any) => {
        setIsSubmitting(true);
        e.preventDefault();
        login({ username, password }).catch(() =>
            notify('Invalid email or password')
        );
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_URL_CUSTOMIZER_ENDPOINT!).then(async(response:any) => {
            const {infos} = await response.json();
            localStorage.setItem('tenantInfos',infos!);
            setLogoImg(JSON.parse(infos)?.mainImage?.url)
        });
    },[])

    const TenantLogo = () => { return <img width={'auto'} height={'auto'} src={logoImg}  alt='logo'/> };

    return (
        <Box sx={styles.loginContainer}>
            <Box sx={styles.loginImage}>
                <TenantLogo />
            </Box>
            <Box sx={styles.loginForm}>
               {isSubmitting
                    ? <Loading loadingPrimary='Connexion en cours' loadingSecondary='Vous allez être redirigé automatiquement merci de patienter...' />
                    :(<>
                        <TextField sx={styles.w250} placeholder='example@g8w.co'  type="email" value={username} onChange={e => setUsername(e.target.value)} />
                        <TextField sx={styles.w250} placeholder='**********' type="password" value={password} onChange={e => setPassword(e.target.value)} />
                        <Button sx={styles.loginButton}  onClick={handleSubmit} type='submit'>Connexion<LockTwoTone /></Button>
                        <Link sx={styles.textPrimary} variant='subtitle1' href='#'>Mot de passe oublié ?</Link>
                    </>)  
                }
            </Box>
        </Box>
    );
};

export default MyLoginPage;