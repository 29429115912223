import { Show,useUpdate,Edit,useRedirect,useNotify } from 'react-admin';
import { UserShowFields } from '../fields';
import { UserEditInputs } from '../inputs/UserInputs';
export const UserShow = () => (
    <Show>
       <UserShowFields />
    </Show>
);

export const UserEdit = () => {
    const [update] = useUpdate()
    const redirect = useRedirect()
    const notify = useNotify()
    const userSave:any = (data:any) => {
        update('User',{data: {data},id:data.id},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Utilisateur modifié avec succès');
        redirect('list','user');
    })
    }
    return (
    <Edit>
        <UserEditInputs userSave={userSave} />
    </Edit>
)};