import buildGraphQLProvider from 'ra-data-graphql-simple';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {authProvider} from './authProvider';
import { Admin,Resource} from 'react-admin';
import MyLoginPage from './MyLoginPage';
import {HttpLink} from "@apollo/client";
import { MyLayout } from "./MyLayout";
import fr from './translations';
import Dashboard from "./Dashboard";
import { myTheme } from './theme';
import * as crud from './screens';
import { Component } from "react";

const header = localStorage.getItem('token')? {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token') || '') }` }:{};
const link = new HttpLink({
        headers: header,
        uri: process.env.REACT_APP_API_ENDPOINT
});

const messages:any = {
    fr: fr
}

const i18nProvider = polyglotI18nProvider(
    locale => messages[locale],
    'fr',
    [{ locale: 'fr', name: 'Français' }]
)

class App extends Component<{},{ dataProvider:any }> {
    constructor(props:any) {
        super(props);
        this.state = { dataProvider: null };
    }
    componentDidMount() {
        // 
        buildGraphQLProvider({ clientOptions: { link }})
            .then(dataProvider => this.setState({ dataProvider }));
    }
    render() {
        const { dataProvider } = this.state;
        if (!dataProvider) { return <div>Loading</div>; }
        return (
            <Admin i18nProvider={i18nProvider} layout={MyLayout} theme={myTheme} dashboard={Dashboard} title={"Manager BO"} dataProvider={dataProvider}  authProvider={authProvider} loginPage={MyLoginPage} requireAuth>
                <Resource name="Employee" list={crud.EmployeeList} create={crud.EmployeeCreate} show={crud.EmployeeShow} edit={crud.EmployeeEdit}   />
                <Resource name="Contract" list={crud.ContractList} create={crud.ContractCreate} show={crud.ContractShow} edit={crud.ContractEdit}  />
                <Resource name="Customer" list={crud.CustomerList} create={crud.CustomerCreate} show={crud.CustomerShow} edit={crud.CustomerEdit} />
                <Resource name="Payslip"  list={crud.PayslipList}  create={crud.PayslipCreate}  show={crud.PayslipShow}  edit={crud.PayslipEdit} />
                <Resource name="Period"   list={crud.PeriodList}   create={crud.PeriodCreate}   show={crud.PeriodShow}   edit={crud.PeriodEdit} />
                <Resource name="Project"  list={crud.ProjectList}  create={crud.ProjectCreate}  show={crud.ProjectShow}  edit={crud.ProjectEdit} />
                <Resource name="File"     list={crud.FileList}     create={crud.FileCreate}     show={crud.FileShow}     edit={crud.FileEdit} />
                <Resource name="Invoice"  list={crud.InvoiceList}  create={crud.InvoiceCreate}  show={crud.InvoiceShow}  edit={crud.InvoiceEdit} />
                <Resource name="Statement"    show={crud.StatementShow} />
                <Resource name="BusinessDay" show={crud.BusinessDayShow} edit={crud.BusinessDayEdit} />
                <Resource name="Organization" show={crud.OrganizationShow} edit={crud.OrganizationEdit} />
                <Resource name="User"         show={crud.UserShow}         edit={crud.UserEdit} />
                <Resource name="Report"   list={crud.ReportsList}   show={crud.ReportsShow}  />
                <Resource name="Ereport" show={crud.EreportShow} />
            </Admin>
        );
    }
}

export default App;