import { Edit,useUpdate,Show,List,useNotify,useRedirect} from 'react-admin';
import { BusinessDayListFields, BusinessDayShowFields } from '../fields/BusinessDayFields';
import { BusinessDayEditInputs } from '../inputs';

export const BusinessDayShow = () => (
    <Show>
        <BusinessDayShowFields />
    </Show>
);

export const BusinessDayList = () => (
    <List>
        <BusinessDayListFields />
    </List>
);

export const BusinessDayEdit = () => {
    const [update] = useUpdate()
    const notify = useNotify();
    const redirect = useRedirect();
    const businessDaySave = (datas:any) => {
        const data = {
            dateStart: datas.dateStart,
            dateEnd: datas.dateEnd,
            status: datas.status,
            type: datas.type,
            range: datas.range
        }
        update('BusinessDay',{data: {data},id:datas.id},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
        }).then(() => {
            notify('Congé modifié avec succès');
            redirect('list','Employee');
        })
    };

    return(
        <Edit>
            <BusinessDayEditInputs businessDaySave={businessDaySave} />
        </Edit>
    )
};