import { SimpleForm,TextInput,SelectInput,ImageInput } from 'react-admin';
import { Box } from '@mui/material';
import { grabImageUrl } from '../screens';
import { MyFileInput } from './CustomInputs';
export const OrganizationEditInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.organizationSave}>
        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <Box sx={{display:'flex',flexDirection:'column',marginRight:2}}>
                    <TextInput source="name" />
                    <TextInput source="code" />
                    <TextInput type='url' source="websiteUrl" />
                    <TextInput source="ownerFirstName" />
                    <TextInput source="ownerLastName" />
                    <TextInput type='email' source="ownerEmail" />
                    <TextInput type='email' source="mail_notification_paye" />
                    <TextInput type='email' source="notifyEmail" />
                </Box>
                <Box sx={{display:'flex',flexDirection:'column',marginRight:2}}>
                    <TextInput type='phone' source="notifyPhone" />
                    <SelectInput disabled source='status' choices={[{id:"status",name:"statut"}]}  />
                    <TextInput source="country" />
                    <TextInput source="city" />
                    <TextInput source="zipCode" />
                    <TextInput source="street" />
                    <TextInput source="legalName" />
                </Box>
                <Box sx={{display:'flex',flexDirection:'column', marginRight:2}}>
                    <TextInput source="twitterUrl" />
                    <TextInput source="facebookUrl" />
                    <TextInput source="instagramUrl" />
                    <TextInput source="linkedinUrl" />
                    <TextInput source="pinterestUrl" />
                    <TextInput source="youtubeUrl" />
                </Box>
                <Box sx={{display:'flex',flexDirection:'column'}}>
                    {/* <ImageInput source="mainImage">
                        <ImageField source="url" />
                    </ImageInput> */}
                    <MyFileInput {...props} source="mainImage" grabUrl={grabImageUrl} />
                    <ImageInput source="logoImage" />
                    <ImageInput source="backgroundImage" />
                </Box>
        </Box>
            
    </SimpleForm>
)