import {  defaultTheme } from 'react-admin';
export const myTheme:any = {
    ...defaultTheme,
    components:{
        ...defaultTheme.components,
        RaDatagrid: {
            styleOverrides:{
                root: {
                    "& .RaDatagrid-headerCell": {
                        backgroundColor: "rgb(250,16,105)",
                        fontWeight:"bold",
                        color:"white"
                    },
                    "& .RaChipField-chip": {
                        backgroundColor: "rgb(250,16,105)",
                        fontWeight:"bold",
                        color: "white"
                    },
                    fontWeight:"bold",
                    textAlign:"center"
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor:"rgb(16,23,96)"
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                fontWeight:"bold",
            }
        },
        MuiTextInput: {
            stylesOverrides: {
                root: {
                    backgroundColor:"Lavender"
                }
            }
        }
    },
}
