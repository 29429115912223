import { Edit,Show,List,Create,useCreate, useUpdate,useNotify,useRedirect} from 'react-admin';
import { FilesFilters } from '../filters';
import { FileShowFields,FileListFields } from '../fields';
import { FileCreateInputs,FileEditInputs } from '../inputs';

export const FileShow = () => (
    <Show>
       <FileShowFields />
    </Show>
);

export const FileList = () => {
    return(
    <List filters={FilesFilters}>
        <FileListFields />
    </List>
)};

let url:any;
export const grabUrl = async (incomingUrl:any) => {
    url = incomingUrl;
    return url;
}

export const FileCreate = () => {
    const [create] = useCreate()
    const redirect = useRedirect()
    const notify = useNotify()
    const fileSave = (data:any) => {
        data["mainFile"].url = url
        create('File',{data: {data}},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Fichier crée avec succès');
        redirect('list','file');
    })
    };
    return(
        <Create redirect="show">
            <FileCreateInputs fileSave={fileSave} />
        </Create>
    )
};

export const FileEdit = () => {
    const [update] = useUpdate()
    const redirect = useRedirect()
    const notify = useNotify()
    const fileSave = (data:any) => {
        const id = data.id
        delete data.id
        data.mainFile = { url: data.mainFile.url }
        update('File',{data: {data},id:id},{returnPromise:true,onError: (error:any) => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Fichier modifié avec succès');
        redirect('list','file');
    })
    };
    return(
        <Edit>
            <FileEditInputs fileSave={fileSave} />
        </Edit>
)};

