import { Badge } from "@mui/material";
import { Menu,useGetOne }  from "react-admin";
import * as icon from './icons';
import { styles } from './style';

const userInfos = JSON.parse(localStorage.getItem('userInfos')!);

export const MyMenu = () => {
   const { data } = useGetOne("DashboardInfos",{id:""});

   return(
     <Menu> 
        <Menu.DashboardItem sx={styles.menuItem} primaryText="Tableau de bord" />
        <Menu.Item sx={styles.menuItem} to="/employee"  primaryText="Employés" leftIcon={<icon.EmployeeIcon />} />
        <Menu.Item sx={styles.menuItem} to="/contract" primaryText={data?.contractsNearEnd.length !== 0 ? <Badge badgeContent={data?.contractsNearEnd.length} color='error'>Contrats</Badge> : "Contrats"} leftIcon={<icon.ContractIcon />} />
        <Menu.Item sx={styles.menuItem} to="/customer" primaryText="Clients"  leftIcon={<icon.CustomerIcon />} /> 
        <Menu.Item sx={styles.menuItem} to="/payslip" primaryText="Bulletins" leftIcon={<icon.PayslipIcon />} />
        <Menu.Item sx={styles.menuItem} to="/period" primaryText={data?.periodsToComplete.length !== 0 ? <Badge badgeContent={data?.periodsToComplete?.length} color='error'>Périodes</Badge>  : "Périodes"} leftIcon={<icon.PeriodIcon />}/> 
        <Menu.Item sx={styles.menuItem} to="/project" primaryText="Projets"   leftIcon={<icon.ProjectIcon />}/>
        <Menu.Item sx={styles.menuItem} to="/file" primaryText="Documents" leftIcon={<icon.FilesIcon />}  />
        <Menu.Item sx={styles.menuItem} to="/report" primaryText="Statistiques" leftIcon={<icon.ReportsIcon />}/>  
        <Menu.Item sx={styles.menuItem} to="/invoice" primaryText="Factures" leftIcon={<icon.InvoicesIcon />}/>  
        <Menu.Item sx={styles.menuItem} to={`/organization/${userInfos?.organization}/show`} primaryText="Mon Organisation" leftIcon={<icon.OrganizationIcon />}/>  
     </Menu>
)
};