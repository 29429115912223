import { SimpleForm,DateInput,SelectInput,FunctionField, ReferenceInput,AutocompleteInput } from 'react-admin';
import { employeeRenderer } from '../renderers';

export const BusinessDayEditInputs = (props:any) => (
    <SimpleForm sanitizeEmptyValues={true} onSubmit={props.businessDaySave}>
            <SelectInput source='type' defaultValue={(record:any) => record.type} fullWidth choices={[{name:"Vacances",id:"vacation"},{name:"Maladie",id:"disease"},{name:"Autre",id:"other"}]} />
            <ReferenceInput source="employee" target="id" reference="Employee">
                <AutocompleteInput optionText={employeeRenderer} />
            </ReferenceInput> 
            <DateInput label="Date" parse={(v:any) => new Date(v).getTime()} source='dateStart' fullWidth />
            <SelectInput source='status' defaultValue={(record:any) => record.status} fullWidth choices={[{name:"En Attente de validation",id:"CREATED"},{name:"Accepté",id:"ACCEPTED"},{name:"Refusé",id:"REJECTED"},{name:"Verrouillé",id:"LOCKED"}]} />
            <FunctionField label="Intervalle" render={(record:any) => record ? `${new Date(parseInt(record?.range.split('-')[0])).toLocaleDateString()} - ${new Date(parseInt(record?.range.split('-')[1])).toLocaleDateString()}` : ''} /> 
    </SimpleForm>
);