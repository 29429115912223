import { Layout }  from "react-admin";
import { ReactQueryDevtools } from 'react-query/devtools';
import {MyMenu}  from "./MyMenu";
import { MyAppBar } from "./MyAppBar";

export const MyLayout = (props:any) => (
    <>
     <Layout {...props} menu={MyMenu} appBar={MyAppBar}/>
     <ReactQueryDevtools initialIsOpen={false} />
    </>

)