import { TextField,ImageField,SimpleShowLayout} from 'react-admin';

export const UserShowFields = () => {
    return(
    <SimpleShowLayout>
        <TextField label="Prénom" source="firstName" />
        <TextField label="Nom de Famille" source="lastName" />
        <TextField label="Email" source="email" />
        <ImageField label='Avatar' source="avatarImage.url" />
    </SimpleShowLayout>
)};
