import { SimpleForm,ReferenceInput,AutocompleteInput,TextInput,FunctionField } from 'react-admin';
import { grabInvoiceUrl } from '../screens';
import { MyFileInput } from './CustomInputs';
import { CentsInput } from './NumberInput';
import { useState } from 'react';
import { employeeRenderer, periodRenderer } from '../renderers';

export const InvoiceCreateInputs = (props:any) => {
        const [employee,setEmployee] = useState('');

        return(
        <SimpleForm sanitizeEmptyValues onSubmit={props.invoiceSave}>
                <ReferenceInput source="employee" reference="Employee" isRequired={true}>
                        <AutocompleteInput onChange={(e:any) => setEmployee(e)} optionText={employeeRenderer} fullWidth isRequired />
                </ReferenceInput>
                <ReferenceInput filter={{employee:employee}} source="month" reference="Period" isRequired={true}>
                        <AutocompleteInput optionText={periodRenderer} fullWidth isRequired />
                </ReferenceInput>
                <CentsInput source='price' isRequired />
                <ReferenceInput source="customer" reference="Customer" isRequired={true}>
                        <AutocompleteInput optionText="name" fullWidth isRequired />
                </ReferenceInput>
                <TextInput source="description" fullWidth />
                <MyFileInput {...props} source="mainFile.url" grabUrl={grabInvoiceUrl} />
        </SimpleForm>
)};

export const InvoiceEditInputs = (props:any) =>  {
        
        const [employee,setEmployee] = useState('');

        return (
                <SimpleForm sanitizeEmptyValues onSubmit={props.invoiceSave}>
                        <ReferenceInput source="employee" reference="Employee" >
                                <AutocompleteInput onChange={(e:any) => setEmployee(e)} optionText={employeeRenderer} fullWidth />
                        </ReferenceInput>
                       <FunctionField render={(record:any) => `Période: ${new Date(parseInt(record.month)).toLocaleDateString('fr-Fr',{month:'numeric',year:'numeric'})}, Contrat: ${record.customerName}`} />
                        <ReferenceInput filter={{employee:employee}} source="period" reference="Period">
                                 <AutocompleteInput optionText={periodRenderer} fullWidth />
                        </ReferenceInput>
                        <CentsInput source='price' isRequired />
                        <ReferenceInput source="customer" reference="Customer">
                                <AutocompleteInput optionText="name" fullWidth />
                        </ReferenceInput>
                        <TextInput source="description" fullWidth />
                        <MyFileInput {...props} source="mainFile.url" grabUrl={grabInvoiceUrl} />
                </SimpleForm>
        )
};