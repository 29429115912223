import  { ChipField, FunctionField, NumberField,ReferenceField,TextField,useRecordContext} from 'react-admin';
import { Fragment } from 'react';


export const CentsField = (props:any) => {
    const record = useRecordContext(props);
    const options = { style:'currency', currency: 'EUR' };

    if (typeof record[props.source] !== 'number' ) { record[props.source] = 0 };
    
    record[props.source] = ( record[props.source] / 100 );

    return (<NumberField {...props} options={options} locales={"fr-FR"} source={props.source}/>);
};

export const StatusField = (props:any) => {
  const alliases:any = {CREATED:'CRÉE',EXPIRES:'EXPIRE BIENTÔT',ACCEPTED:'ACCEPTÉ',REJECTED:'REFUSÉ',COMPLETED:'FINI',ONGOING:'EN COURS',RENEWED:'RENOUVELÉ',LOCKED:'ARCHIVÉ'}
  const record = useRecordContext(props);
  if (Object.keys(alliases).includes(record[props.source])) record[props.source] = alliases[record[props.source]];
  return (<ChipField {...props} source={props.source} />)
}

export const PercentField = (props:any) => {
    const record = useRecordContext(props);
    const options = { style:'percent' };

    if (typeof record[props.source] !== 'number' ) {record[props.source] = 0};

    record[props.source] = record[props.source] / 100;

    return (<NumberField {...props} options={options} locales={"fr-FR"}  source={props.source} />);
};

export const JsonObjectField = (props:any) => {
  const record = useRecordContext(props);
  const fields = [];
  const alliases:any = {employeesTotalBaseAccountAmount:"Total des comptes de réserves",employeesTotalGwFeesAmount:"Total des frais gestion GW",employees: "Nombre d'employés"}
 
  if (record.result.length <= 10) return <div>Pas de données</div>;
  
  const result = JSON.parse(`{${record.result}}`);

  for (const key in result) {
    fields.push(
      <FunctionField
        key={key}
        label={key}
        render={() => alliases[key] + ' : ' + key === 'employees' ? result[key]  : `${(result[key] / 100).toLocaleString('fr-FR')} €`}
      />
    );
  }

  return <Fragment>{fields}</Fragment>;
};

export const CustomerField = (props:any) => {
 return(
    <ReferenceField label="Client" source="customer" reference="Customer">
      <TextField label='Client' source='name' />
    </ReferenceField>
  )
};

export const EmployeeField = (props:any) => {
  return (
    <ReferenceField label="Employé" source="employee" reference="Employee">
      <FunctionField render={(record:any) => `${record.firstName} ${record.lastName}`} />
    </ReferenceField>
  )
}

export const ContractField = (props:any) => {
  return (
    <ReferenceField label="Contrat" source="contract" reference="Contract">
      <FunctionField render={(record:any) => `${record.customerName}`} />
    </ReferenceField>
  )
}