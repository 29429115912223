import { Edit,Show,useUpdate,useRedirect,useNotify } from 'react-admin';
import { OrganizationShowFields } from '../fields'; 
import { OrganizationEditInputs } from '../inputs/OrganizationInputs';

export const OrganizationShow = () => (
    <Show>
        <OrganizationShowFields />
    </Show>
);

let url:any;
export const grabImageUrl = async (incomingUrl:any) => {
    console.log('called at least',incomingUrl);
    url = incomingUrl;
    console.log(url)
    return url;
}

export const OrganizationEdit = () => {
    const [update] = useUpdate()
    const redirect = useRedirect()
    const notify = useNotify()
    const organizationSave:any = (datas:any) => {
        const mainImage = {url:url};
        const data = {
            websiteUrl: datas?.websiteUrl,
            country: datas?.country,
            city: datas?.city,
            zipCode: datas?.zipCode,
            street: datas?.street,
            legalName: datas?.legalName,
            notifyEmail: datas?.notifyEmail,
            mail_notification_paye: datas?.mail_notification_paye,
            notifyPhone: datas?.notifyPhone,
            twitterUrl: datas?.twitterUrl,
            facebookUrl: datas?.facebookUrl,
            instagramUrl: datas?.instagramUrl,
            linkedinUrl: datas?.linkedinUrl,
            pinterestUrl: datas?.pinterestUrl,
            youtubeUrl: datas?.youtubeUrl,
            mainImage: mainImage
        }
        update('Organization',{data: {data},id:datas.id},{returnPromise:true,onError: error => {
            notify((error as Error).message, {type: 'error'});
        },
    }).then(() => {
        notify('Organisation modifiée avec succès');
        redirect('show','organization');
    })
    }
    return(
    <Edit>
        <OrganizationEditInputs organizationSave={organizationSave} />
    </Edit>
)};