import { Title,useGetOne,Loading } from "react-admin";
import { CalendarToday } from '@mui/icons-material';
import { Box,Typography } from '@mui/material';
import * as Components from './components';
import Card from '@mui/material/Card';
import { DashboardIcon } from './icons';
import { styles } from './style';

const Dashboard = () => {

    const { data, isLoading } = useGetOne("DashboardInfos",{id:""});
    if (isLoading) return <Loading />

    return(
        <Box>
            <Title title={"Tableau de bord"} />
            <Card sx={[styles.top,styles.textCenter,{margin:4}]}>
                <Typography sx={styles.customListTitle} variant='h4'><DashboardIcon />Tableau de Bord</Typography>
                <Box sx={styles.dashboardHeader}>
                        <CalendarToday />
                        <Typography>{new Date().toLocaleString('FR-fr',{ year: 'numeric', month: 'long', day: 'numeric'})}</Typography>
                </Box>
                <Components.DashboardHeaderCards data={data} />
            </Card>
            <Card sx={styles.dashboardCard}>
                <Components.PeriodsToCompleteList data={data} isLoading={isLoading} />
            </Card>
            <Card sx={styles.dashboardCard}>
                <Components.ContractsAboutToExpiresList data={data} isLoading={isLoading} />
            </Card>
            <Card sx={styles.dashboardCard}>
                <Components.BusinessDaysToValidateList data={data} isLoading={isLoading} />
            </Card>
        </Box>
   )};

export default Dashboard;